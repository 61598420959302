export const H1Data = {
  rAndD23: "Nil",
  rAndD22: "Nil",
  rAndDDetails: "-",
  capex23: "Nil",
  capex22: "Nil",
  capexDetails: "-",
  variable: "The Company has not incurred any R&D expenditure and CAPEX investments in specific technologies to improve environmental and social impacts of products and processes."
};

export const H2Data = {
  sustainable: "Dixon inherently believes in the principle of sustainable sourcing, and understands that sustainable sourcing / procurement can greatly contribute towards ensuring responsible operations of the Company. Although the Company does not have an established sustainable procurement strategy in place, it is continually undertaking measures to integrate the sustainable sourcing / procurement principle in the overall procurement strategy of the Company.",
  percentage: "N.A.",
};

export const H3Data = {
  plastic: "The Company is Committed to improving waste management strategies across it's facilities. The Company is also having a robust waste management systems to ensure disposal of waste in Compliance with applicable legislations/ Laws. Dixon Technologies operates on a B2B (business to business) model, and they manufacture products for some of the leading companies in the electronics industry. During the operations different types of waste is generated such as plastic waste, paper waste, electronic waste etc. Company has tied-up with the authorized third-party company to safely dispose the processing waste such as Greeniva recycler. On the other hand, since Company operates in B2B segment, the provision of reclaiming of products in not applicable to the Company.",
  ewaste: "",
  hazardous: "",
  otherReuse: "",
};

export const H4Data = {
  applicable: "Yes, Extended Producer Responsibility (EPR) is applicable to the Company’s activities. Yes, the waste collection plan of the Company is in line with the Extended Producer Responsibility (EPR) plan submitted to Pollution Control Boards (PCB).",
  plan: "",
  step: "",
};

export const H5Data = {
  nicCode: "",
  conducted: "",
  name: "",
  turnover: "",
  results: "",
  boundary: "",
};

export const H6Data = {
  name: "",
  description: "",
  action: "",
};

export const H7Data = {
  inputMaterial: "",
  year: "",
  lastYear: "",
};

export const H8Data = {
  plasticReuse: "",
  plasticRecycle: "",
  plasticSafely: "",
  ewasteReuse: "",
  ewasteRecycle: "",
  ewasteSafely: "",
  hazardousReuse: "",
  hazardousRecycle: "",
  hazardousSafely: "",
  otherReuse: "",
  otherRecycle: "",
  otherSafely: "",
};

export const H9Data = {
  category: "",
  percentage: "",
};
