import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H1Data } from "./data.js";

const headerData1 = [
  { label: "Name and brief details of project", align: "center" },
  { label: "SIA Notification No.", align: "center"},
  { label: "Date of notification", align: "center"},
  { label: "Whether conducted by independent external agency (Yes / No)", align: "center"},
  { label: "Results communicated in public domain (Yes / No)", align: "center"},
  { label: "Relevant Web link", align: "center"},

];

const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      {label}
    </TableCell>
  </TableRow>
);

const H1 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(6, H1Data.project)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H1;
