import { H1Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData1 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "Disclosure Questions", align: "center", rowSpan: 2 },
  { label: "P1", align: "center", rowSpan: 1 },
  { label: "P2", align: "center", rowSpan: 1 },
  { label: "P3", align: "center", rowSpan: 1 },
  { label: "P4", align: "center", rowSpan: 1 },
  { label: "P5", align: "center", rowSpan: 1 },
  { label: "P6", align: "center", rowSpan: 1 },
  { label: "P7", align: "center", rowSpan: 1 },
  { label: "P8", align: "center", rowSpan: 1 },
  { label: "P9", align: "center", rowSpan: 1 },
];

const headerData2 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "Principles", align: "left", rowSpan: 2 },
  {
    label:
      "Name of the national and international codes/certifications/labels/standards mapped to each principle.",
    align: "center",
    rowSpan: 2,
  },
];

const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      <strong>{label}</strong>
    </TableCell>
  </TableRow>
);

const renderDataRows = (data) =>
  data.map(({ id, label, data, fontSize, align }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell
          style={{ fontSize: fontSize }}
          key={index}
          align={align ? align : "center"}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const renderSingleResponse = ({ id, key, value }) => (
  <TableRow key={id}>
    <TableCell component="th" scope="row" align="center">
      {id}
    </TableCell>
    <TableCell>{key}</TableCell>
    <TableCell key={id} colSpan={9} align="left">
      {value}
    </TableCell>
  </TableRow>
);

const renderSingleResponseLink = ({ id, key, value }) => (
  <TableRow key={id}>
    <TableCell component="th" scope="row" align="center">
      {id}
    </TableCell>
    <TableCell>{key}</TableCell>
    <TableCell key={id} colSpan={9} align="left">
      <a href={value}>{value}</a>
    </TableCell>
  </TableRow>
);

const getCertificationTable = () => (
  <TableRow>
    <TableCell colSpan={11} align="left">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(
                ({ label, align, rowSpan, colSpan, colWidth }, index) => (
                  <TableCell
                    key={index}
                    align={align}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    style={{ width: colWidth }}
                  >
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                label: "Principle 1",
                data: [H1Data.codeCertificationsP1],
                align: "left",
              },
              {
                label: "Principle 2",
                data: [H1Data.codeCertificationsP2],
                align: "left",
              },
              {
                label: "Principle 3",
                data: [H1Data.codeCertificationsP3],
                align: "left",
              },
              {
                label: "Principle 4",
                data: [H1Data.codeCertificationsP4],
                align: "left",
              },
              {
                label: "Principle 5",
                data: [H1Data.codeCertificationsP5],
                align: "left",
              },
              {
                label: "Principle 6",
                data: [H1Data.codeCertificationsP6],
                align: "left",
              },
              {
                label: "Principle 7",
                data: [H1Data.codeCertificationsP7],
                align: "left",
              },
              {
                label: "Principle 8",
                data: [H1Data.codeCertificationsP8],
                align: "left",
              },
              {
                label: "Principle 9",
                data: [H1Data.codeCertificationsP9],
                align: "left",
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>{" "}
    </TableCell>
  </TableRow>
);

const H1 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(
                ({ label, align, rowSpan, colSpan, colWidth }, index) => (
                  <TableCell
                    key={index}
                    align={align}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    style={{ width: colWidth }}
                  >
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(12, "Policy and management processes")}
            {renderDataRows([
              {
                id: "1a",
                label:
                  " Whether your entity’s policy/policies cover each principle and its core elements of the NGRBCs. (Yes/No)",
                data: [
                  H1Data.isPolicyP1,
                  H1Data.isPolicyP2,
                  H1Data.isPolicyP3,
                  H1Data.isPolicyP4,
                  H1Data.isPolicyP5,
                  H1Data.isPolicyP6,
                  H1Data.isPolicyP7,
                  H1Data.isPolicyP8,
                  H1Data.isPolicyP9,
                ],
              },
              {
                id: "1b",
                label: "Has the policy been approved by the Board? (Yes/No)",
                data: [
                  H1Data.isPolicyApprovedP1,
                  H1Data.isPolicyApprovedP2,
                  H1Data.isPolicyApprovedP3,
                  H1Data.isPolicyApprovedP4,
                  H1Data.isPolicyApprovedP5,
                  H1Data.isPolicyApprovedP6,
                  H1Data.isPolicyApprovedP7,
                  H1Data.isPolicyApprovedP8,
                  H1Data.isPolicyApprovedP9,
                ],
              },
            ])}
            {renderSingleResponseLink({
              id: "1c",
              key: "Web Link of the Policies, if available**",
              value: H1Data.linkOfPolicies,
            })}
            {renderDataRows([
              {
                id: "2",
                label:
                  "Whether the entity has translated the policy into procedures. (Yes / No)",
                data: [
                  H1Data.translatedPolicyP1,
                  H1Data.translatedPolicyP2,
                  H1Data.translatedPolicyP3,
                  H1Data.translatedPolicyP4,
                  H1Data.translatedPolicyP5,
                  H1Data.translatedPolicyP6,
                  H1Data.translatedPolicyP7,
                  H1Data.translatedPolicyP8,
                  H1Data.translatedPolicyP9,
                ],
              },
            ])}
            {renderSingleResponse({
              id: "3",
              key: "Do the enlisted policies extend to your value chain partners? (Yes/No)",
              value: H1Data.isValueChainPartners,
            })}
            {renderSingleResponse({
              id: "4",
              key: "Name of the national and international codes/certifications/labels/standards (e.g., Forest Stewardship Council, Fairtrade, Rainforest Alliance, Trustee) standards (e.g., SA 8000, OHSAS, ISO, BIS) mapped to each principle.",
              value: H1Data.codeCertificationsRemark,
            })}
            {getCertificationTable()}
            {renderSingleResponse({
              id: "5",
              key: "Specific commitments, goals and targets set by the entity with defined timelines, if any.",
              value: H1Data.goals,
            })}
            {renderSingleResponse({
              id: "6",
              key: "Performance of the entity against the specific commitments, goals, and targets along-with reasons in case the same are not met.",
              value: H1Data.performance,
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H1;
