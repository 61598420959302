

export const SectionASection = [
    { title: "I. Details of the listed entity" },
    { title: "II. Products/services" },
    { title: "III. Operations" },
    { title: "IV. Employees" },
    { title: "V. Holding, Subsidiary and Associate Companies (INCLUDING JOINT VENTURES)" },
    { title: "VI. CSR Details" },
    { title: "VII. Transparency and Disclosures Compliances" }
];
  