import { H2Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";


const headerData2 = [
  { label: "Sr. No", align: "center", rowSpan: 2 },
  { label: "Name of the trade and industry chambers/ associations", align: "center", rowSpan: 2 },
  { label: "Reach of trade and industry chambers/ associations (State/National)", align: "center", rowSpan: 2 },
];

const H2 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  style={{width: colWidth}}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {
            H2Data.map((data, index) => (
              <TableRow key={++index}>
                <TableCell key={index} align="center">
                  {data.serial}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.name}
                </TableCell>
                <TableCell  style={{fontSize: "12px"}} key={index} align="center">
                  {data.reach}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H2;
