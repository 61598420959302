import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H4Data } from "./data.js";

const headerData2 = [
  { label: "Category", align: "center", rowSpan: 2 },
  { label: "FY 2022-23", align: "center", colSpan: 1},
  { label: "FY 2021-22", align: "center", colSpan: 1 },
];


const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {
        id == 1 ?
        <>
        <TableCell rowSpan={2} colSpan={1} align="center">
          {H4Data.msme23}
        </TableCell>
        <TableCell rowSpan={2} colSpan={1} align="center">
        {H4Data.msme22}
      </TableCell>
        </>  : ""
      }
    </TableRow>
  ));

const H6 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Directly sourced from MSMEs/ small producers",
                data: [],
              },
              {
                id: 2,
                label: "Sourced directly from within the district and neighboring districts",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <p>
        {H4Data.variable}
      </p>
    </div>
  );
};

export default H6;
