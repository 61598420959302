import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P8Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P8/H1';
import H2 from './P8/H2';
import H3 from './P8/H3';
import H4 from './P8/H4';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

const { Panel } = Collapse;

const Principle8 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P8Heading[0].description:
        return <H1 />;
      case P8Heading[1].description:
        return <H2 />;
      case P8Heading[2].description:
        return <H3 />;
      case P8Heading[3].description:
        return <H4 />;
      default:
        return <H1 />;
    }
  }

  const highlight = [
    { name: "Customer complaints on critical issues", icon: <FactoryIcon style={{ fontSize: "42px" }} />, value: "Nil" },
    { name: "Available on company website", icon: <GroupsOutlinedIcon style={{ fontSize: "42px" }} />, value: "Data Privacy Policy" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="Dixon Technologies is dedicated to promoting inclusive growth and equitable development in communities where the offices and plants of the company are located. Our business strategy is underpinned by our deep concern for society. " onClose={onClose} open={open}>
        <p>The Company views organisational performance and community wellbeing as inextricably linked. We recognise the significance of inclusive growth in the overall development of the economy. According to the Company's CSR policy, the efforts are carried out by the CSR committee of the Board.</p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[9].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[9].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[0]}
            ghost
          >
            {
              P8Heading.map((data, index) => {
                if(index<4) {
                return (
                  <>
                    <Panel header={++index + ". " +data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }
          </Collapse>
        </Col>
      </Row>
      <br/>
    </div>
  );
};
export default Principle8;