import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button} from 'antd';
import { P7Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P7/H1';
import H2 from './P7/H2';
import H3 from './P7/H3';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';

const { Panel } = Collapse;

const Principle7 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P7Heading[0].description:
        return <H1 />;
      case P7Heading[1].description:
        return <H2 />;
      case P7Heading[2].description:
        return <H3 />;
      default:
        return <H1 />;
    }

  }

  const highlight = [
    { name: "National affiliation with trade and industry chambers / associations", icon: <CurrencyExchangeOutlinedIcon style={{ fontSize: "42px" }} />, value: "9" },
    { name: "Instance of anti-competitive conduct", icon: <DangerousOutlinedIcon style={{ fontSize: "42px" }} />, value: "Nil" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="Dixon Technologies recognizes the importance of responsible and transparent public policy advocacy. " onClose={onClose} open={open}>
        <p>The Company exclusively participates in forums that have an influence on the interests of industry and its stakeholders in broad
areas such as governance and administration, economic reforms, environmental safety, energy security, sustainable business
practices, taxation, and water, among others. The Company's engagements with the relevant authorities are governed by the
values of dedication, integrity, transparency, and the need to balance the interests of varied stakeholders.</p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[8].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[8].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[0]}
            ghost
          >
            {
              P7Heading.map((data, index) => {
                if(index<3) {
                return (
                  <>
                    <Panel header={data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
      <br/>
    </div>
  );
};
export default Principle7;