import {Col, Collapse, Row, Space } from "antd";
import { BRSRSchema } from "../constants/Principle";
import H1 from "./SectionA/H1";
import H2 from "./SectionA/H2";
import H3 from "./SectionA/H3";
import H4 from "./SectionA/H4";
import H5 from "./SectionA/H5";
import H6 from "./SectionA/H6";
import H7 from "./SectionA/H7";
import { SectionASection } from "../constants/PageSection";
import { appTheme } from "../config";
import * as React from "react";
import Card from "@mui/material/Card";
import { generalDislosureComponent, leadershipMessageComponent } from "../utility/Common";
import { Grid } from "@mui/material";
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';


const { Panel } = Collapse;

const SectionA = () => {
  const getHeadingData = (title) => {
    switch (title) {
      case "I. Details of the listed entity":
        return <H1 />;
      case "II. Products/services":
        return <H2 />;
      case "III. Operations":
        return <H3 />;
      case "IV. Employees":
        return <H4 />;
      case "V. Holding, Subsidiary and Associate Companies (INCLUDING JOINT VENTURES)":
        return <H5 />;
      case "VI. CSR Details":
        return <H6 />;
      case "VII. Transparency and Disclosures Compliances":
        return <H7 />;
      default:
        return <H1 />;
    }
  };

  const highlight = [
    { name: "Plants", icon: <FactoryIcon style={{ fontSize: "42px" }} />, value: "13" },
    { name: "Employees", icon: <GroupsOutlinedIcon style={{ fontSize: "42px" }} />, value: "1,551" },
    { name: "Workers", icon: <GroupOutlinedIcon style={{ fontSize: "42px" }} />, value: "11,204" },
    { name: "Female Workers", icon: <Face3OutlinedIcon style={{ fontSize: "42px" }} />, value: "36%" },
    { name: "Subsidiaries", icon: <FactoryIcon style={{ fontSize: "42px" }} />, value: "6" },
  ];

  return (
    <div>
      <Row style={{ paddingBottom: "10px" }}>
        <Col offset={1} span={22}>
          <Card
            sx={{
              background: appTheme.colors.secondry,
              paddingLeft: "20px",
              alignItems: "center",
            }}
          >
            <h1
              style={{
                color: "white",
                paddingTop: "12px",
                alignItems: "center",
              }}
            >
              {BRSRSchema[0].title}
            </h1>
            <p style={{ color: "white" }}>{BRSRSchema[0].description}</p>
          </Card>
        </Col>
      </Row>

      {leadershipMessageComponent()}
      {generalDislosureComponent(highlight)}
      
      <Row>
        <Col offset={1} span={22}>
          <Collapse className="primary-color" defaultActiveKey={[0]} ghost>
            {SectionASection.map((data, index) => {
              return (
                <>
                  <Panel header={data.title} key={index}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {getHeadingData(data.title)}
                    </Space>
                  </Panel>
                </>
              );
            })}
          </Collapse>
        </Col>
      </Row>
    </div>
  );
};
export default SectionA;
