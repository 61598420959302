import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H8Data } from "./data.js";

const headerData1 = [
  { label: "Parameter", align: "center", rowSpan: 2 },
  { label: "FY 2022-23", align: "center"},
  { label: "FY 2021-22", align: "center"},
];

const renderDataRows = (data) =>
  data.map(({ id, label, data, bold }) => (
    <TableRow key={id}>
      <TableCell style={{fontWeight: bold ? "bold" : "normal"}}>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell style={{fontWeight: bold ? "bold" : "normal"}} key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const renderTableRow = (colSpan, label, align) => (
  <TableRow>
    <TableCell component="th" scope="row" align={ align ? align : "center"} colSpan={colSpan}>
      <strong>{label}</strong>
    </TableCell>
  </TableRow>
);

const H8 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(0, "Total Waste generated (in metric tonnes)", "left")}
            {renderDataRows([
              {
                id: 1,
                label: "Plastic waste (A)",
                data: [
                  H8Data.plastic23,
                  H8Data.plastic22
                ],
              },
              {
                id: 2,
                label: "E-waste (B)",
                data: [
                  H8Data.ewaste23,
                  H8Data.ewaste22
                ],
              },
              {
                id: 3,
                label: "Bio-medical waste (C)",
                data: [
                  H8Data.bioMedical23,
                  H8Data.bioMedical22
                ],
              },
              {
                id: 4,
                label: "Construction and demolition waste (D)",
                data: [
                  H8Data.construction23,
                  H8Data.construction22
                ],
                
              },
              {
                id: 5,
                label: "Battery Waste (E)",
                data: [
                  H8Data.battery23,
                  H8Data.battery22
                ],
              },
              {
                id: 6,
                label: "Radioactive waste (F)",
                data: [
                  H8Data.radioActive23,
                  H8Data.radioActive22
                ],
              },
              {
                id: 7,
                label: "Other Hazardous waste. Please specify, if any. (G)",
                data: [
                  H8Data.otherHazardous23,
                  H8Data.otherHazardous22
                ],
              },
              {
                id: 8,
                label: "Other Non-hazardous waste generated (H). Please specify, if any. (Break-up by composition i.e., by materials relevant to the sector)",
                data: [
                  H8Data.otherNonHazardous23,
                  H8Data.otherNonHazardous22
                ],
              },
              {
                id: 9,
                label: "Total (A+B + C + D + E + F + G + H)",
                data: [
                  H8Data.total23,
                  H8Data.total22
                ],
                bold: true
              },
            ])}
            {renderTableRow(3, "For each category of waste generated, total waste recovered through recycling-using or other recovery operations (in metric tonnes)")}
            {renderTableRow(1, "Category of waste", "left")}
            {renderDataRows([
              {
                id: 1,
                label: "(i) Recycled",
                data: [
                  H8Data.recycled23,
                  H8Data.recycled22
                ],
              },
              {
                id: 2,
                label: "(ii) Re-used",
                data: [
                  H8Data.reused23,
                  H8Data.reused22
                ],
              },
              {
                id: 3,
                label: "(iii) Other recovery operations	",
                data: [
                  H8Data.otherRecoveryOption23,
                  H8Data.otherRecoveryOption22
                ],
              },
              {
                id: 4,
                label: "Total",
                data: [
                  H8Data.totalRecovery23,
                  H8Data.totalRecovery22
                ],
                bold: true
                
              },])}
              {renderTableRow(3, "For each category of waste generated, total waste disposed by nature of disposal method (in metric tonnes)")}
            {renderTableRow(1, "Category of waste", "left")}
            {renderDataRows([
              {
                id: 1,
                label: "(i) Incineration	",
                data: [
                  H8Data.incineration23,
                  H8Data.incineration22
                ],
              },
              {
                id: 2,
                label: "(ii) Landfilling",
                data: [
                  H8Data.landfilling23,
                  H8Data.landfilling22
                ],
              },
              {
                id: 3,
                label: "(iii) Other disposal operations	",
                data: [
                  H8Data.otherDisposal23,
                  H8Data.otherDisposal22
                ],
              },
              {
                id: 4,
                label: "Total",
                data: [
                  H8Data.totalDisposal23,
                  H8Data.totalDisposal22
                ],
                bold: true
              },])}
          </TableBody>
        </Table>
      </TableContainer>
      <p>{H8Data.variable}</p>
      <br></br>
      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency. - NO</p>
    </div>
  );
};

export default H8;
