import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H5Data } from "./data.js";


const headerData5 = [
  { label: "", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "FY 2022-23", align: "center", rowSpan: 2, colWidth: "40%" },
  { label: "FY 2021-22", align: "center", rowSpan: 2, colWidth: "50%" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H5 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData5.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Directors",
                data: [
                  H5Data.director_23,
                  H5Data.director_22,

                ],
              },
              {
                id: 2,
                label: "KMPs",
                data: [
                  H5Data.kmp_23,
                  H5Data.kmp_22,

                ],
              },
              {
                id: 3,
                label: "Employees",
                data: [
                  H5Data.employee_23,
                  H5Data.employee_22,

                ],
              },
              {
                id: 4,
                label: "Workers",
                data: [
                  H5Data.worker_23,
                  H5Data.worker_22,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H5;
