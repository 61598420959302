import { H4Data } from "./data.js";

const H4 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H4Data.isZeroLiquidDischarge}</p>
    </div>
  );
};

export default H4;
