import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P2Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P2/H1';
import H2 from './P2/H2';
import H3 from './P2/H3';
import H4 from './P2/H4';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';

const { Panel } = Collapse;

const Principle7 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P2Heading[0].description:
        return <H1 />;
      case P2Heading[1].description:
        return <H2 />;
      case P2Heading[2].description:
        return <H3 />;
      case P2Heading[3].description:
        return <H4 />;
      default:
        return <H1 />;
    }
  }

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="" onClose={onClose} open={open}>
        <p>Dixon technologies is placed at a strategic position in the supply chain, as it is manufacturing the electronic products
for some of the leading electronic brands across the globe. As a result of its global footprint, it is primordial
for the Company to comply with both national as well as international rules and regulations. Also, it needs to
keep its manufacturing capacity, including the infrastructural as well as technical capacity, at par with the best
global practices. Resultantly, the Company has been continually innovating its product design and manufacturing
capabilities. Further, its operational activities take sustainability parameters into consideration as it acknowledges
that a truly sustainable company should act towards preserving the environment, proving labor/employee friendly
environment at the workplace while ensuring the economic efficiency of the Company. </p>
<p>
The Company's business endeavors are bifurcated into two distinct categories: ODM (Original Design Manufacturer) and OEM
(Original Equipment Manufacturer). Under the OEM classification, the Company follows directives furnished by the business
client throughout the entire product manufacturing trajectory – spanning from design conception to actual production and
packaging. Conversely, in the ODM segment, the Company conceptualizes its own design, which subsequently evolves into the
final product. However, the approval of the Business client remains imperative in this process as well. As such, irrespective of the
product category, the Company ensures that its products are developed in a sustainable and responsible manner. For instance,
the Company provides optimal workplace conditions for its factory workers, prioritizing their safety. With an environmental
focus, the Company has strategically integrated solar panels at various sites and is progressively transitioning to cleaner fuel
sources, such as PNG. This ecological approach significantly diminishes the Company's environmental footprint, contributing
to an overall reduction in greenhouse gas emissions during operations. Moreover, stringent quality assurance procedures are
executed, exemplified by the lighting segment, where QR codes are affixed to products, enabling product traceability to address
any arising issues. Consequently, the Company's collective endeavors exemplify its commitment to delivering superior-quality
products while concurrently safeguarding the environment and the communities within which it operates.
</p>      </Drawer>
      </>
    )
  }

  const highlight = [
    { name: "with Extended Producer Responsibility (EPR)", icon: <MarkChatReadOutlinedIcon style={{ fontSize: "42px" }} />, value: "Compliant" },
    { name: "with authorized third parties for waste recycling", icon: <RecyclingOutlinedIcon style={{ fontSize: "42px" }} />, value: "Tie Up" },
  ];

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[3].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[3].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[0]}
            ghost
          >
            {
              P2Heading.map((data, index) => {
                if(index<4) {
                return (
                  <>
                    <Panel header={++index + ". " +data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }
          </Collapse>
        </Col>
      </Row>
      <br/>
    </div>
  );
};
export default Principle7;