import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Button, Drawer } from 'antd';
import { SectionBHeading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './SectionB/H1';
import H2 from './SectionB/H2';
import H3 from './SectionB/H3';
import H4 from './SectionB/H4';
import H5 from './SectionB/H5';
import H6 from './SectionB/H6';
import H7 from './SectionB/H7';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import Co2OutlinedIcon from '@mui/icons-material/Co2Outlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import RecyclingOutlinedIcon from '@mui/icons-material/RecyclingOutlined';

const { Panel } = Collapse;

const SectionB = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case SectionBHeading[0].description:
        return <H1 />;
      case SectionBHeading[1].description:
        return <H2 />;
      case SectionBHeading[2].description:
        return <H3 />;
      case SectionBHeading[3].description:
        return <H4 />;
      case SectionBHeading[4].description:
        return <H5 />;
      case SectionBHeading[5].description:
        return <H6 />;
      case SectionBHeading[6].description:
        return <H7 />;
      default:
        return <H1 />;
    }

  }

  const highlight = [
    { value: "Great Place to work", icon: <AutoAwesomeOutlinedIcon style={{ fontSize: "42px" }} />, name: "Certificate" },
    { value: "Carbon neutral", icon: <Co2OutlinedIcon style={{ fontSize: "42px" }} />, name: "By 2030" },
    { value: "Recycle 100% E-waste", icon: <RecyclingOutlinedIcon style={{ fontSize: "42px" }} />, name: "By 2030" },
    { value: "Reduce water intensity", icon: <WaterDropOutlinedIcon style={{ fontSize: "42px" }} />, name: "By 5% by 2027" },
    { value: "ESG Committee", icon: <PeopleOutlinedIcon style={{ fontSize: "42px" }} />, name: "At Board level" },
  ];


  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[1].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[1].description}</p>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[1]}
            ghost
          >
            {
              SectionBHeading.map((data, index) => {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              })
            }

          </Collapse>
        </Col>
      </Row>
     
    </div>
  );
};
export default SectionB;