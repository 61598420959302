import { Divider } from "antd";
import {
  H5Data,
} from "./data.js";

const H5 = () => {
  return (
    <div className="text-padding-left">
      <p><strong>{H5Data.isPolicy}</strong></p>  
      <p><a href={H5Data.link}>{H5Data.link}</a></p>
      <Divider/>
    </div>
  );
};

export default H5;
