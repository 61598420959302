import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P1Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P1/H1';
import H2 from './P1/H2';
import H3 from './P1/H3';
import H4 from './P1/H4';
import H5 from './P1/H5';
import H6 from './P1/H6';
import H7 from './P1/H7';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined';
import MarkChatReadOutlinedIcon from '@mui/icons-material/MarkChatReadOutlined';

const { Panel } = Collapse;

const Principle1 = () => {

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P1Heading[0].description:
        return <H1 />;
      case P1Heading[1].description:
        return <H2 />;
      case P1Heading[2].description:
        return <H3 />;
      case P1Heading[3].description:
        return <H4 />;
      case P1Heading[4].description:
        return <H5 />;
      case P1Heading[5].description:
        return <H6 />;
      case P1Heading[6].description:
        return <H7 />;
      default:
        return <H1 />;
    }
  }
  
  const highlight = [
    { name: "Material fines/penalties/ punishments as per Regulation 30 of SEBI LODR", icon: <CurrencyRupeeOutlinedIcon style={{ fontSize: "42px" }} />, value: "Nil" },
    { name: "Disciplinary actions for the charges of bribery/ corruption", icon: <RuleOutlinedIcon style={{ fontSize: "42px" }} />, value: "Nil" },
    { name: "Complaints on conflict of Interest", icon: <MarkChatReadOutlinedIcon style={{ fontSize: "42px" }} />, value: "Zero" }
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="Dixon Technologies gives significant importance to conducting its business activities with integrity and ethically" onClose={onClose} open={open}>
        <p>A good corporate governance framework instills trust amongst the customers and other stakeholders and promote longterm sustainability and value-creation, reduces business risks, improves operational efficiency, fosters innovation and growth and enhances the reputation and credibility of the company. As a result, the Company has established a robust corporate governance framework which guides it to conform to regulatory compliances and promote transparency about the business conduct to its stakeholders. To enable this, Company has diverse Board of Directors with wide professional and industrial experience essential to create value for the business as well as the stakeholders. Company has formulated and effected various policies which acts a guide to the Board of Directors senior management and other employees of the Company. The Code of Conduct includes best of the practices to be followed by everyone i.e. employees, senior management and Directors to ensure that the business activities have been conducted in compliance with the applicable laws and other best practices has been followed. The Code of Conduct can be accessed on the Company website, www.dixoninfo.com. This demonstrates the Company’s commitment to promoting transparency, accountability and carrying out business activities with integrity and ethical practices</p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[2].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[2].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[0]}
            ghost
          >
            {
              P1Heading.map((data, index) => {
                if(index<7) {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
      <br/>
    </div>
  );
};
export default Principle1;