import { H23Data, H24Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData23 = [
  { label: "Stakeholder group from whom complaint is received", align: "center", rowSpan: 2, colWidth: "20%" },
  { label: "Grievance Redressal Mechanism in Place (Yes/No)", align: "center", colSpan: 1, colWidth: "40%" },
  { label: "FY23", align: "center", colSpan: 3, colWidth: "15%"},
  { label: "FY22", align: "center", colSpan: 3, colWidth: "15%" },
];

const subHeaderData23 = [
  { label: "(If yes, then provide web-link for grievance redress policy)", align: "center" },
  { label: "Number of complaints filed during the year", align: "center" },
  { label: "Number of complaints pending resolution at close of the year", align: "center" },
  { label: "Remarks", align: "center"},
  { label: "Number of complaints filed during the year", align: "center" },
  { label: "Number of complaints pending resolution at close of the year", align: "center" },
  { label: "Remarks", align: "center"},
];

const headerData24 = [
  { label: "Material issue identified", align: "center", rowSpan: 2, colWidth: "8%" },
  { label: "Indicate Whether risk or opportunity (R/O)", align: "center", rowSpan: 2, colWidth: "4%" },
  { label: "Rationale for identifying the risk / opportunity", align: "center", rowSpan: 2, colWidth: "30%"},
  { label: "In case of risk, approach to adapt or mitigate", align: "center", rowSpan: 2, colWidth: "25%" },
  { label: "Financial implications of the risk or opportunity (Indicate positive or negative implications)", align: "center", rowSpan: 2, colWidth: "25%" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align={index === 0 ? "left" : "center"}>
          {index === 0 ? <div style={{fontSize: "12px"}} dangerouslySetInnerHTML= {{ __html: value }} /> : value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H7 = () => {
  return (
    <div>
      <p>
        <strong>
          VII-23. Complaints/Grievances on any of the principles Principles (one to nine) under the National Guidelines on Responsible Business Conduct:
        </strong>
      </p>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light, fontSize: "12px", fontWeight: 400 } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData23.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData23.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Communities",
                data: [
                  H23Data.communitiesLink,
                  H23Data.communitiesFiled_23,
                  H23Data.communitiesPending_23,
                  H23Data.comminitiesRemark_23,
                  H23Data.communitiesFiled_22,
                  H23Data.communitiesPending_22,
                  H23Data.comminitiesRemark_22
                ],
              },
              {
                id: 2,
                label: "Investors (other than shareholders)",
                data: [
                  H23Data.investorsLink,
                  H23Data.investorsFiles_23,
                  H23Data.investorsPending_23,
                  H23Data.investorsRemark_23,
                  H23Data.investorsFiles_22,
                  H23Data.investorsPending_22,
                  H23Data.investorsRemark_22,
                ],
              },
              {
                id: 3,
                label: "Shareholders",
                data: [
                  H23Data.shareholderLink,
                  H23Data.shareholderFiles_23,
                  H23Data.shareholderPending_23,
                  H23Data.shareholderRemark_23,
                  H23Data.shareholderFiles_22,
                  H23Data.shareholderPending_22,
                  H23Data.shareholderRemark_22,
                ],
              },
              {
                id: 4,
                label: "Employees and workers",
                data: [
                  H23Data.employeeLink,
                  H23Data.employeeFiled_23,
                  H23Data.employeePending_23,
                  H23Data.employeeRemark_23,
                  H23Data.employeeFiled_22,
                  H23Data.employeePending_22,
                  H23Data.employeeRemark_22,
                ],
              },{
                id: 5,
                label: "Customers",
                data: [
                  H23Data.customerLink,
                  H23Data.customerFiled_23,
                  H23Data.customerPending_23,
                  H23Data.customerRemark_23,
                  H23Data.customerFiled_22,
                  H23Data.customerPending_22,
                  H23Data.customerRemark_22,
                ],
              },{
                id: 6,
                label: "Value Chain Partners",
                data: [
                  H23Data.partnerLink,
                  H23Data.partnerFiled_23,
                  H23Data.partnerPending_23,
                  H23Data.partnerRemark_23,
                  H23Data.partnerFiled_22,
                  H23Data.partnerPending_22,
                  H23Data.partnerRemark_22,
                ],
              },{
                id: 7,
                label: "Others",
                data: [
                  H23Data.otherLink,
                  H23Data.otherFiled_23,
                  H23Data.otherPendinng_23,
                  H23Data.otherRemark_23,
                  H23Data.otherFiled_22,
                  H23Data.otherPendinng_22,
                  H23Data.otherRemark_22,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

      <br/>
      <p><strong>VII-24. Overview of the entity's material responsible business conduct issues. Please indicate material responsible business conduct and sustainability issues pertaining to environmental and social matters that present a risk or an opportunity to the business, rationale for identifying the same, approach to adapting or mitigating the risk along-with its financial implications, as per the following format.</strong></p>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData24.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  style={{width: colWidth}}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {
            H24Data.map((data, index) => (
              <TableRow key={++index}>
                <TableCell key={index} align="left">
                  {data.materialIssue}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.riskOpportunity}
                </TableCell>
                <TableCell  style={{fontSize: "12px"}} key={index} align="left">
                  {data.rationale}
                </TableCell>
                <TableCell style={{fontSize: "12px"}} key={index} align="left">
                  {data.mitigate}
                </TableCell>
                <TableCell style={{fontSize: "12px"}} key={index} align="left">
                  {data.implication}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
      

    </div>
  );
};

export default H7;
