export const H1Data = {
  totalPermanentEmployee: "Nil",
  coveredPermanentEmployee: "",
  percentagePermanentEmployee: "",
  totalOtherThanPermanentEmployee: "",
  coveredOtherThanPermanentEmployee: "",
  percentageOtherThanPermanentEmployee: "",
  totalTotalEmployee: "",
  coveredTotalEmployee: "",
  percentageTotalEmployee: "",
  totalPermanentWorker: "Nil",
  coveredPermanentWorker: "",
  percentagePermanentWorker: "",
  totalOtherThanPermanentWorker: "",
  coveredOtherThanPermanentWorker: "",
  percentageOtherThanPermanentWorker: "",
  totalTotalWorker: "",
  coveredTotalWorker: "",
  percentageTotalWorker: "",
};
export const H2Data = {
  totalPermanentMaleEmployee23: "1,694",
  equalToMinimumPermanentMaleEmployeeNumber23: "0",
  equalToMinimumPermanentMaleEmployeePercentage23: "N.A.",
  moreThanMinimumPermanentMaleEmployeeNumber23: "1,694",
  moreThanMinimumPermanentMaleEmployeePercentage23: "100%",
  totalPermanentFemaleEmployee23: "63",
  equalToMinimumPermanentFemaleEmployeeNumber23: "0",
  equalToMinimumPermanentFemaleEmployeePercentage23: "N.A.",
  moreThanMinimumPermanentFemaleEmployeeNumber23: "63",
  moreThanMinimumPermanentFemaleEmployeePercentage23: "100%",
  totalOtherThanPermanentMaleEmployee23: "0",
  equalToMinimumOtherThanPermanentMaleEmployeeNumber23: "0",
  equalToMinimumOtherThanPermanentMaleEmployeePercentage23: "N.A.",
  moreThanMinimumOtherThanPermanentMaleEmployeeNumber23: "N.A.",
  moreThanMinimumOtherThanPermanentMaleEmployeePercentage23: "0%",
  totalOtherThanPermanentFemaleEmployee23: "0",
  equalToMinimumOtherThanPermanentFemaleEmployeeNumber23: "0",
  equalToMinimumOtherThanPermanentFemaleEmployeePercentage23: "N.A.",
  moreThanMinimumOtherThanPermanentFemaleEmployeeNumber23: "N.A.",
  moreThanMinimumOtherThanPermanentFemaleEmployeePercentage23: "0%",
  totalPermanentMaleWorker23: "203",
  equalToMinimumPermanentMaleWorkerNumber23: "0",
  equalToMinimumPermanentMaleWorkerPercentage23: "N.A.",
  moreThanMinimumPermanentMaleWorkerNumber23: "203",
  moreThanMinimumPermanentMaleWorkerPercentage23: "100%",
  totalPermanentFemaleWorker23: "3",
  equalToMinimumPermanentFemaleWorkerNumber23: "0",
  equalToMinimumPermanentFemaleWorkerPercentage23: "N.A.",
  moreThanMinimumPermanentFemaleWorkerNumber23: "3",
  moreThanMinimumPermanentFemaleWorkerPercentage23: "100%",
  totalOtherThanPermanentMaleWorker23: "7,150",
  equalToMinimumOtherThanPermanentMaleWorkerNumber23: "3,504",
  equalToMinimumOtherThanPermanentMaleWorkerPercentage23: "49%",
  moreThanMinimumOtherThanPermanentMaleWorkerNumber23: "3,646",
  moreThanMinimumOtherThanPermanentMaleWorkerPercentage23: "51%",
  totalOtherThanPermanentFemaleWorker23: "3,850",
  equalToMinimumOtherThanPermanentFemaleWorkerNumber23: "1,848",
  equalToMinimumOtherThanPermanentFemaleWorkerPercentage23: "48%",
  moreThanMinimumOtherThanPermanentFemaleWorkerNumber23: "2,002",
  moreThanMinimumOtherThanPermanentFemaleWorkerPercentage23: "52%",
  totalPermanentMaleEmployee22: "1,711",
  equalToMinimumPermanentMaleEmployeeNumber22: "0",
  equalToMinimumPermanentMaleEmployeePercentage22: "N.A.",
  moreThanMinimumPermanentMaleEmployeeNumber22: "1,711",
  moreThanMinimumPermanentMaleEmployeePercentage22: "100%",
  totalPermanentFemaleEmployee22: "44",
  equalToMinimumPermanentFemaleEmployeeNumber22: "0",
  equalToMinimumPermanentFemaleEmployeePercentage22: "N.A.",
  moreThanMinimumPermanentFemaleEmployeeNumber22: "44",
  moreThanMinimumPermanentFemaleEmployeePercentage22: "100%",
  totalOtherThanPermanentMaleEmployee22: "0",
  equalToMinimumOtherThanPermanentMaleEmployeeNumber22: "0",
  equalToMinimumOtherThanPermanentMaleEmployeePercentage22: "N.A.",
  moreThanMinimumOtherThanPermanentMaleEmployeeNumber22: "0",
  moreThanMinimumOtherThanPermanentMaleEmployeePercentage22: "0%",
  totalOtherThanPermanentFemaleEmployee22: "0",
  equalToMinimumOtherThanPermanentFemaleEmployeeNumber22: "0",
  equalToMinimumOtherThanPermanentFemaleEmployeePercentage22: "N.A.",
  moreThanMinimumOtherThanPermanentFemaleEmployeeNumber22: "0",
  moreThanMinimumOtherThanPermanentFemaleEmployeePercentage22: "0%",
  totalPermanentMaleWorker22: "260",
  equalToMinimumPermanentMaleWorkerNumber22: "0",
  equalToMinimumPermanentMaleWorkerPercentage22: "N.A.",
  moreThanMinimumPermanentMaleWorkerNumber22: "260",
  moreThanMinimumPermanentMaleWorkerPercentage22: "100%",
  totalPermanentFemaleWorker22: "2",
  equalToMinimumPermanentFemaleWorkerNumber22: "0",
  equalToMinimumPermanentFemaleWorkerPercentage22: "N.A.",
  moreThanMinimumPermanentFemaleWorkerNumber22: "2",
  moreThanMinimumPermanentFemaleWorkerPercentage22: "100%",
  totalOtherThanPermanentMaleWorker22: "7,781",
  equalToMinimumOtherThanPermanentMaleWorkerNumber22: "3,735",
  equalToMinimumOtherThanPermanentMaleWorkerPercentage22: "48%",
  moreThanMinimumOtherThanPermanentMaleWorkerNumber22: "4,046",
  moreThanMinimumOtherThanPermanentMaleWorkerPercentage22: "52%",
  totalOtherThanPermanentFemaleWorker22: "4,190",
  equalToMinimumOtherThanPermanentFemaleWorkerNumber22: "2,053",
  equalToMinimumOtherThanPermanentFemaleWorkerPercentage22: "49%",
  moreThanMinimumOtherThanPermanentFemaleWorkerNumber22: "2,137",
  moreThanMinimumOtherThanPermanentFemaleWorkerPercentage22: "51%",

};
export const H3Data = {
  maleNumberBoardOfDirector: "7",
  maleMedianSalaryBoardOfDirector: "24,75,990",
  femaleNumberBoardOfDirector: "1",
  femaleMedianSalaryBoardOfDirector: "-",
  maleNumberKeyManagerialPerson: "4",
  maleMedianSalaryKeyManagerialPerson: "18,02,813",
  femaleNumberKeyManagerialPerson: "Nil",
  femaleMedianSalaryKeyManagerialPerson: "Nil",
  maleNumberEmployee: "1,757",
  maleMedianSalaryEmployee: "56,924",
  femaleNumberEmployee: "123",
  femaleMedianSalaryEmployee: "42,553",
  maleNumberWorker: "203",
  maleMedianSalaryWorker: "21,604",
  femaleNumberWorker: "3",
  femaleMedianSalaryWorker: "22,870",
};

export const H4Data = {
  isFocalPointCommittee: "Yes, the company's grievance committee and Chief Human Resource Officer are in charge of dealing with human rights issues. The company believes in conducting all of its business activities in the best possible way by adhering to the highest standards of Corporate Governance. As a result, anyone experiencing a problem may contact the appropriate authority to file a complaint, and quick action will be made to address the issue.",
};

export const H5Data = {
  grievancesMechanism: "Dixon is resolute in preventing any breaches of human rights, ensuring Policy adherence through a mechanism overseen by the HR Department and routinely monitored by an Internal Committee. The HR departments at both plant locations and the headquarters maintain consistent oversight of human rights considerations. Stakeholders have secure, round-the-clock access to raise grievances or report potential Human Rights violations anonymously via the DARWIN portal. The Company has established a well-defined process for addressing grievances related to human rights concerns. This encompasses an internal committee known as the Grievance Committee, dedicated to effectively and promptly resolving the concerns of employees and workers. Furthermore, a separate POSH (Prevention of Sexual Harassment) committee is in place, specifically tasked with addressing issues related to sexual harassment, demonstrating the Company's careful attention to such sensitive matters. Additionally, employees, workers, senior management, and other stakeholders can report human rightsrelated concerns to their superiors or use the whistle-blower mechanism.",
};

export const H6Data = {
  sexualHarassmentFiled: "No complaints have been filed by employees and workers under any of the categories.",
  sexualHarassmentPending: "",
  sexualHarassmentRemarks: "",
  discriminationFiled: "",
  discriminationPending: "",
  discriminationRemarks: "",
  childLabourFiled: "",
  childLabourPending: "",
  childLabourRemarks: "",
  forcedLabourFiled: "",
  forcedLabourPending: "",
  forcedLabourRemarks: "",
  wagesFiled: "",
  wagesPending: "",
  wagesRemarks: "",
  otherHumanRightsFiled: "",
  otherHumanRightsPending: "",
  otherHumanRightsRemarks: "",
};

export const H7Data = {
  adverseConsequences: "Dixon Technologies maintains a dedicated and comprehensive non-discrimination and non-harassment policy, meticulously designed to address instances of discrimination and harassment within the organization. The Company also possesses a grievance redressal framework encompassing various stakeholders, reinforced by a dedicated committee tasked with addressing concerns. Upholding the utmost ethical standards, the Company has introduced a Whistleblower channel, enabling the reporting of legitimate concerns, whether they pertain to unlawful or unethical practices, to competent authorities. The Company guarantees the confidentiality of the complainant while prohibiting any form of retaliation, which includes threats, job loss, punitive assignments, or negative impacts on remuneration. If a complainant feels subjected to retaliation, they have the option to formally file a written complaint with the Chairman of the Audit Committee. The Company also has a policy on prevention at sexual harassment at workplace. The policy has been framed in accordance with Sexual Harassment of Woman at Workplace (Prevention, Prohibition and Redressal) Act, 2013.",
};

export const H8Data = {
  isbusinessAgreement: "Yes, Dixon technologies gives utmost importance to safeguarding human rights of the people. The Company has a Code of Conduct to which the Company continually encourages its supply chain partners, contractors and customers to abide by the same. As a result of this practice, Company has incorporated the human rights related clause, such as forced and child labor as part of its business agreements and contractors. The Company on timely basis conducts independent audit of its plant operations to avoid any sort of child Labour and malpractices.",
};

export const H9Data = {
  sexualHarassment: "100%",
  discrimination: "",
  childLabour: "",
  forcedLabour: "",
  wages: "",
  others: "",
};

export const H10Data = {
  correctiveAction: "Dixon technologies, being a committed and responsible corporate citizen acknowledges that as much important it is to establish a better system in place to address any issues related to human rights or other parameters, as is important to have robust and independent monitoring system in place. An independent assessment procedure takes a neutral approach and does the monitoring without any biases. As a result, the Company also gets independent assessment done for all of its plants on parameters like child Labour, forced and involuntary Labour and for wages. During the assessment, no risks has been identified under any of the mentioned categories. As a result, no corrective actions were required to be taken.",
};

export const H11Data = {
  modification: "",
};

export const H12Data = {
  dueDilligence: "",
};

export const H13Data = {
  accessibility: "",
};

export const H14Data = {
  sexualHarassment: "",
  discrimination: "",
  childLabour: "",
  forcedLabour: "",
  wages: "",
  others: "",
};

export const H15Data = {
  correctiveAction: "",
};
