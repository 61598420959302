export const H1Data = {
  electicictyConsumption23: "97.8",
  fuelConsumption23: "10.2",
  energyOtherSource23: "0",
  totalEnergy23: "108",
  energyIntesity23: "0.015",
  energyIntensityOptional23: "0",
  electicictyConsumption22: "86",
  fuelConsumption22: "20.30",
  energyOtherSource22: "0",
  totalEnergy22: "106.30",
  energyIntesity22: "0.014",
  energyIntensityOptional22: "0",
  independentAssesment: "No",
  variable: "* Turnover of the Company for the FY 2022-23 is Rs. 6,99,740 Lakhs and for FY 2021-22 is Rs. 7,48,441 Lakhs"
};

export const H2Data = {
  isDesignatedConsumer: "No, Company has not identified any sites/facilities as designated consumers (DCs) under the Performance, Achieve and Trade (PAT). However, being a responsible Company, significant importance is given to incorporating sustainability parameters into the business strategies.",
  targetAchieved: "",
};

export const H3Data = {
  surface23: "0",
  ground23: "68,683",
  thirdParty23: "0",
  seaDesalinated23: "0",
  other23: "0",
  totalWithdrawal23: "68,683",
  totalConsumption23: "68,683",
  waterIntensity23: "10",
  waterIntensityOptional23: "-",
  surface22: "0",
  ground22: "62,392",
  thirdParty22: "0",
  seaDesalinated22: "0",
  other22: "0",
  totalWithdrawal22: "62,392",
  totalConsumption22: "62,392",
  waterIntensity22: "8.3",
  waterIntensityOptional22: "-",
  independentAssesment: "No",
  variable: "* Turnover of the Company for the FY 2022-23 is H 6,99,740 Lakhs and for FY 2021-22 is H 7,48,441 Lakhs"  
};

export const H4Data = {
  isZeroLiquidDischarge: "Yes, the Company has undertaken several initiatives which would result in reducing the water consumption and effective management of the wastewater generated during the operations. Also, since Company is in electronics manufacturing industry, the use of water in the manufacturing process is almost negligible. However, significant amount of water is used during the other operational activities of the Company. It has installed STPs (Sewage Treatment Plant) in at most of their plant locations to treat the wastewater. The treated water is subsequently reused in other activities like gardening, cleaning or in the washrooms. All our units have Zero Liquid discharges mechanism as specified by the Pollution Control Board.",
  details: "",
};

export const H5Data = {
  variable: "Dixon technologies complies with the emissions norms prescribed by the government authorities in the jurisdiction of its operations. Further, it ensures to keep the emission of air pollutants restricted to the number authorized by the regulatory bodies as provided in the Consent to Operation (CTO) certificate of the Company. To ensure the compliance, Company gets the quarterly stack emissions analysis from an independent authority.",
  nox23: "1.9",
  sox23: "20.4",
  pm23: "1.64",
  pop23: "0",
  voc23: "0",
  hap23: "0",
  others23: "-",
  nox22: "Not available",
  sox22: " Not available ",
  pm22: " Not available ",
  pop22: "0",
  voc22: "0",
  hap22: "0",
  others22: "-",
  independentAssesment: "No",
};

export const H6Data = {
  scope1_23: "826",
  scope2_23: "17,454",
  total_23: "2.6",
  scope1_22: "892",
  scope2_22: "18,582",
  total_22: "2.6",
  independentAssesment: "No",
  variable: "* Turnover of the Company for the FY 2022-23 is H 6,99,740 Lakhs and for FY 2021-22 is H 7,48,441 Lakhs"
};

export const H7Data = {
  isProjectGhg: "Yes, the Company has been several steps towards reducing its carbon footprint by incorporating the sustainability principles in the overall business strategy and operations. Some of the initiatives undertaken by the Company to limit the greenhouse gas emissions which includes, inventorization of greenhouse gas emissions, installation of solar panels at various locations including the plants and office location, transitioning from the HSD (High Speed Diesel) consumption to PNG (Piped Natural Gas), new plants being established are green building certified, use of energy efficient equipment. Further, Company is also working on to upgrade its fleet of buses used for employee commute from BS4 to BS6. Moreover, the Company has always been adopting specific water consumption reduction.",
  details: "",
};

export const H8Data = {
  plastic23: "9,050",
  ewaste23: "NA",
  bioMedical23: "NA",
  construction23: "NA",
  battery23: "NA",
  radioActive23: "NA",
  otherHazardous23: "Used Oil 1,641",
  otherNonHazardous23: "Corrugated Box & Wooden - 74,608",
  total23: "85,229",
  recycled23: "Nil",
  reused23: "Nil",
  otherRecoveryOption23: "Nil",
  totalRecovery23: "",
  incineration23: "1,288",
  landfilling23: "1,188",
  otherDisposal23: "82,823",
  totalDisposal23: "85,299",
  plastic22: "NA",
  ewaste22: "NA",
  bioMedical22: "NA",
  construction22: "NA",
  battery22: "NA",
  radioActive22: "NA",
  otherHazardous22: "-",
  otherNonHazardous22: "-",
  total22: "",
  recycled22: "Nil",
  reused22: "Nil",
  otherRecoveryOption22: "Nil",
  totalRecovery22: "",
  incineration22: " Nil ",
  landfilling22: " Nil ",
  otherDisposal22: " Nil ",
  totalDisposal22: "Nil",
  independentAssesment: "",
};

export const H9Data = {
  wasteManagementPractices: "The Company has implemented a robust waste management system to efficiently handle waste generated during manufacturing processes and overall operations. The foundation of this approach rests on the 3Rs framework: Reduce, Reuse, and Recycle, which aims to curb waste generation in the first place. A meticulous step-by-step process ensures effective waste management. Beginning with proper segregation at the point of origin, this process enhances recyclability and reusability of waste while minimizing the potential for environmental contamination. In particular, for the management of e-waste and plastic waste, the Company has established partnerships with governmentauthorized recyclers. This collaboration diverts waste from landfills, fostering recyclability and responsible waste management. Notably, in the mobile and laptop manufacturing sector, waste generated during production doesn't remain within the Company's premises for disposal, with the exception of laptop packaging waste, for which the Company assumes responsibility. Instead, all types of waste are returned to customers, who are accountable for their appropriate management. Furthermore, the Company has embraced the Restriction of Hazardous Substances (ROHS) directive. This entails the restriction of six hazardous materials – lead (Pb), mercury (Hg), cadmium (Cd), hexavalent chromium (Cr6), polybrominated biphenyls (PBB), and polybrominated diphenyl ether (PBDE) – in the manufacturing of electronic and electrical devices.",
};

export const H10Data = {
  location: "Company is committed to use the ecological resources responsibly and sustainably and protecting the rich biodiversity of the planet. Company ensures that none of its operational activities has any severe and irreversible impact on the biological resources or biodiversity. As a result, Company ensures that none of its operations or offices are located in/around ecologically sensitive areas/protected areas such as national parks, wildlife sanctuaries etc. All of its office locations and plants are located in the industrial area.",
  is: "",
  reason: "",
  operation: "",
};

export const H11Data = {
  name: "During the reporting period, no projects undertaken by the Company needed to get the Environment Impact Assessment (EIA) done.",
  notification: "",
  date: "",
  isConducted: "",
  isPublic: "",
  link: "",
};

export const H12Data = {
  law: "Yes, the Company is compliant and no fines were levied on the Company in FY 2022-23. All of the Company’s plants are complying with the norms prescribed by the concerned pollution control Boards.",
  details: "",
  fine: "",
  corrective: "",
};

export const H13Data = {
  totalRenewableElectricity: "",
  totalRenewableFuel: "",
  otherRenewableSources: "",
  totalRenewableEnergy: "",
  totalNonRenewableElectricity: "",
  totalNonRenewableFuel: "",
  otherNonRenewableSources: "",
  totalNonRenewableEnergy: "",
  independentAssesment: "",
};

export const H14Data = {
  surface: "",
  surfaceNoTreatment: "",
  surfaceWithTreatment: "",
  groundwater: "",
  groundwaterNoTreatment: "",
  groundwaterWithTreatment: "",
  seawater: "",
  seawaterNoTreatment: "",
  seawaterWithTreatment: "",
  thirdParty: "",
  thirdPartyNoTreatment: "",
  thirdPartyWithTreatment: "",
  others: "",
  othersNoTreatment: "",
  othersWithTreatment: "",
  totalWaterDischarge: "",
  independentAssesment: "",
};

export const H15Data = {
  area: "",
  natureOfOperation: "",
  waterWithdrawal: "",
  surface: "",
  ground: "",
  thirdParty: "",
  seaDesalinated: "",
  otherSource: "",
  totalWithdrawal: "",
  totalConsumption: "",
  waterIntensity: "",
  waterIntensityOptional: "",
  independentAssesment: "",
  surfaceDischarge: "",
  surfaceNoTreatmentDischarge: "",
  surfaceWithTreatmentDischarge: "",
  levelOfTreatementSurface: "",
  groundwaterDischarge: "",
  groundwaterNoTreatmentDischarge: "",
  groundwaterWithTreatmentDischarge: "",
  levelOfTreatementGroundwater: "",
  seawaterDischarge: "",
  seawaterNoTreatmentDischarge: "",
  seawaterWithTreatmentDischarge: "",
  levelOfTreatementSeawater: "",
  thirdPartyDischarge: "",
  thirdPartyNoTreatmentDischarge: "",
  thirdPartyWithTreatmentDischarge: "",
  levelOfTreatementThirdParty: "",
  othersDischarge: "",
  othersNoTreatmentDischarge: "",
  othersWithTreatmentDischarge: "",
  levelOfTreatementOther: "",
  totalWaterDischarge: "",
};

export const H16Data = {
  scope3: "",
  turnover: "",
  relevantMetric: "",
  independentAssesment: "",
};

export const H17Data = {
  biodiversity: "",
};

export const H18Data = {
  initiative: "",
  details: "",
  outcome: "",
};

export const H19Data = {
  isPolicy: "",
  link: "",
};

export const H20Data = {
  adverseImpact: "",
};

export const H21Data = {
  valueChainPartner: "",
};
