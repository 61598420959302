import BootstrapTable from "react-bootstrap-table-next";
import {
  H14Data,
  H15Data,
} from "./data.js";

const H2 = () => {

  const mainActivityFormatter = (cell, row) => {
    if (row.visible) {
      return (
        <div style={{margin: "-12px", padding: "12px"}} className="table-cell-border-top">
          <span >{cell}</span>
        </div>
      );
    }
    return null;
  };
  
  const columnsTab1 = [
    {
      dataField: "id",
      text: "S No",
      headerStyle: { width: "5%",},
      headerClasses: "secondry-color-background font-white border-top-left-radius "
    },
    {
      dataField: "mainActivity",
      text: "Description of Main Activity",
      headerStyle: { width: "25%"},
      formatter: mainActivityFormatter,
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "business",
      text: "Descriptoin of Business Activity",
      headerStyle: { width: "45%" },
      classes: "table-cell-border-top",
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "turnover",
      text: "Entity Turnover (%)",
      headerStyle: { width: "10%" },
      classes: "table-cell-border-top",
      headerClasses: "secondry-color-background font-white border-top-right-radius"
    },
  ];

  const columnsTab2 = [
    {
      dataField: "id",
      text: "S No",
      classes: "table-cell-border-top",
      headerStyle: { width: "5%",},
      headerClasses: "secondry-color-background font-white border-top-left-radius "
    },
    {
      dataField: "product",
      text: "Product/Service",
      classes: "table-cell-border-top",
      headerStyle: { width: "40%" },
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "nic",
      text: "NIC Code",
      classes: "table-cell-border-top",
      headerStyle: { width: "40%" },
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "turnover",
      text: "Total Turnover Contribution (%)",
      classes: "table-cell-border-top",
      headerStyle: { width: "20%" },
      headerClasses: "secondry-color-background font-white border-top-right-radius"
    },
  ];


  const formattedData14 = (data) => {
    let row = 1;
    let groupSize = 0;
    let previousMainActivity = null;
  
    return data.map((record, index) => {
      if (index === 0 || record.mainActivity !== previousMainActivity) {
        // Determine the group size
        groupSize = data.slice(index + 1).findIndex(r => r.mainActivity !== record.mainActivity);
        groupSize = groupSize === -1 ? data.length - index : groupSize + 1; // Adjust for correct count
        previousMainActivity = record.mainActivity;
  
        // Mark as first in group and assign ID
        return { ...record, isFirstInGroup: true, groupSize, visible: true, id: row++ };
      } else {
        // Not the first in the group, so make it invisible and do not assign an ID
        return { ...record, isFirstInGroup: false, groupSize: 0, visible: false, id: null };
      }
    });
  };

  const formattedData15 = (data) => {
    return data.map((record, index) => {
      return { ...record, id: ++index };
    });
  };


  return (
    <div>
      <p>II-14. Details of business activities (accounting for 90% of the turnover):</p>
      <BootstrapTable 
        keyField="id" 
        classes="column-style"
        data={formattedData14(H14Data)} 
        bordered={false}
        columns={columnsTab1} 
      />
      <br />
      <p>II-15. Products/Services sold by the entity (accounting for 90% of the entity's Turnover):</p>
      <BootstrapTable 
        keyField="id" 
        classes="column-style"
        data={formattedData15(H15Data)} 
        bordered={false}
        columns={columnsTab2} 
      />
    </div>
  );
};

export default H2;
