import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P5Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P5/H1';
import H2 from './P5/H2';
import H3 from './P5/H3';
import H4 from './P5/H4';
import H5 from './P5/H5';
import H6 from './P5/H6';
import H7 from './P5/H7';
import H8 from './P5/H8';
import H9 from './P5/H9';
import H10 from './P5/H10';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import WomanOutlinedIcon from '@mui/icons-material/WomanOutlined';
import SportsKabaddiOutlinedIcon from '@mui/icons-material/SportsKabaddiOutlined';
import ThumbUpOffAltOutlinedIcon from '@mui/icons-material/ThumbUpOffAltOutlined';

const { Panel } = Collapse;

const Principle5 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P5Heading[0].description:
        return <H1 />;
      case P5Heading[1].description:
        return <H2 />;
      case P5Heading[2].description:
        return <H3 />;
      case P5Heading[3].description:
        return <H4 />;
      case P5Heading[4].description:
        return <H5 />;
      case P5Heading[5].description:
        return <H6 />;
      case P5Heading[6].description:
        return <H7 />;
      case P5Heading[7].description:
        return <H8 />;
      case P5Heading[8].description:
        return <H9 />;
      case P5Heading[9].description:
        return <H10 />;
      default:
        return <H1 />;
    }

  }

  const highlight = [
    { value: "More than Minimum Wages", icon: <CurrencyRupeeOutlinedIcon style={{ fontSize: "42px" }} />, name: "to employees" },
    { value: "Higher Median Wage for Female", icon: <WomanOutlinedIcon style={{ fontSize: "42px" }} />, name: "permanent workers compared to male" },
    { value: "Chief Human Resource Officer", icon: <SportsKabaddiOutlinedIcon style={{ fontSize: "42px" }} />, name: "Focal point for human right impacts and issues" },
    { value: "Zero", icon: <Face3OutlinedIcon style={{ fontSize: "42px" }} />, name: "Human right complaints" },
    { value: "100%", icon: <ThumbUpOffAltOutlinedIcon style={{ fontSize: "42px" }} />, name: "Plant & offices covered under human right assessment" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="" onClose={onClose} open={open}>
        <p>Human Rights constitute the inherent entitlements that every individual possesses by virtue of their humanity, encompassing
essential facets such as the right to life and liberty.</p>
<p>
Dixon Technologies recognizes its responsibility to uphold and advance the human rights of all individuals it engages with.
Consequently, the Company is dedicated to ensuring that its business operations uphold human rights, refraining from any
infringement. Beyond fostering well-being, this commitment engenders trust in the Company among stakeholders, a pivotal
factor in the Company's sustained success.
</p>

<p>
To advance human rights, the Company has established and implemented a range of policies. For instance, a dedicated NonDiscrimination and Non-Harassment Policy outlines the Company's commitment to prevent any form of discrimination or
harassment within the organization. Furthermore, the policy outlines provisions for disciplinary measures against those who
breach its stipulations. 
</p>

<p>
Further, Company actively engages with its stakeholders to address their human rights concerns. 
</p>

<p>Dixon technologies believes that safeguarding and promoting human rights should not just be act of legal compliance. Rather, it
should be a moral imperative of all the citizens, including corporate citizens to make this world a better place to thrive. 
  </p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[6].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[6].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[1]}
            ghost
          >
            {
              P5Heading.map((data, index) => {
                if(index<10) {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
    </div>
  );
};
export default Principle5;