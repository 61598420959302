export const H1Data = {
  mechanism: "Dixon Technologies recognizes the pivotal role of stakeholders in establishing enduring value for the Company and propelling business expansion. Consequently, the Company emphasizes the crucial task of identifying key stakeholders and establishing a robust engagement framework to regularly interact with them. This consistent stakeholder engagement process nurtures a resilient connection and rapport between the Company and its stakeholders. Although the Company doesn't follow a formal procedure to identify stakeholders, it employs an ad-hoc approach. This approach identifies groups and entities as stakeholders if their perspectives, actions, and opinions wield substantial influence over the overall business and are significantly impacted by the Company's operations. As a result, the Company designates investors, shareholders, bankers, directors, employees, local communities, suppliers, contractors, customers, and governmental authorities as key stakeholders. The Company also has a Stakeholder Engagement policy which is placed on its website at 'Codes and Policies and Terms'"
};

export const H2Data = [{
  stakeholderGroup: "Investors/ Shareholders/ Bankers",
  isVulnerableMarginalized: "No",
  channelOfCommunication: "• Annual General meeting • Company's website • Investor / analyst meet / calls • Annual report",
  frequency: "Quarterly, annually and as and when required",
  purpose: "Purpose is to comply with statutory compliances and communicate them about the performance of the Company",
}, {
  stakeholderGroup: "Employees",
  isVulnerableMarginalized: "No",
  channelOfCommunication: "Emails, Employee survey, Notice board, Annual performance review, website, meetings, trainings etc. Company website, Employee engagement activities",
  frequency: "As and when required",
  purpose: "To foster a good relationship with the Company and employees which helps in promoting employee wellbeing, performance, career development of the employees etc.",
}, {
  stakeholderGroup: "Directors",
  isVulnerableMarginalized: "No",
  channelOfCommunication: "Quarterly board meeting is conducted with the attendance of the directors of the Company.",
  frequency: "As and when required",
  purpose: "The purpose is to formulate the long-term strategy for the Company with keeping the regular oversight over the implementation and overall performance of the Company.",
}, {
  stakeholderGroup: "Community",
  isVulnerableMarginalized: "Yes",
  channelOfCommunication: "CSR Projects",
  frequency: "On regular basis",
  purpose: "The purpose is to enable the social wellbeing of the Community members and establish stronger bond with them.",
}, {
  stakeholderGroup: "Customer & Suppliers",
  isVulnerableMarginalized: "No",
  channelOfCommunication: "Emails, Newspaper, Advertising, websites, face-to-face meetings, phone calls and social media, Company website",
  frequency: "As and when required",
  purpose: "Customers and suppliers are one of the most important drivers of the business. Therefore, regular interactions with them enables us to address their queries and issues in effective and timely manner and build stronger relationships with them",
}, {
  stakeholderGroup: "Government Authorities",
  isVulnerableMarginalized: "No",
  channelOfCommunication: "Regulatory Bodies – SEBI, Stock Exchanges, Ministry of Corporate Affairs, RoC. Through various Stock Exchange and ROC Filings, which includes intimation of events categorized under Reg 30 of SEBI LODR, other event-based compliances such as Corporate Governance Report, Filing of Financial Statements, etc.",
  frequency: "Quarterly, half yearly and as and when required.",
  purpose: "Purpose is to comply with the regulatory requirements."
},
];

export const H3Data = {
  consultation: ""
};

export const H4Data = {
  isSupportIdentification: "",
  details: ""
};

export const H5Data = {
  instancesOfEngagement: ""
};
