import { H18Data, H19Data, H20Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData18 = [
  { label: "No.", align: "center", rowSpan: 2 },
  { label: "Particulars", rowSpan: 2 },
  { label: "Total(A)", align: "center", rowSpan: 2 },
  { label: "Male", align: "center", colSpan: 2 },
  { label: "Female", align: "center", colSpan: 2 },
];

const subHeaderData18 = [
  { label: "No. (B)", align: "center" },
  { label: "% (B/A)", align: "center" },
  { label: "No. (C)", align: "center" },
  { label: "% (C/A)", align: "center" },
];

const headerData19 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "", rowSpan: 2 },
  { label: "Total(A)", align: "center", rowSpan: 2 },
  { label: "No. and percentage of females", align: "center", colSpan: 4 },
];

const subHeaderData19 = [
  { label: "No. (B)", align: "center" },
  { label: "% (B/A)", align: "center" },
];

const headerData20 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "", align: "center", rowSpan: 2 },
  { label: "FY 2022-23", align: "center", colSpan: 3 },
  { label: "FY 2022-23", align: "center", colSpan: 3 },
  { label: "FY 2022-23", align: "center", colSpan: 3 },
];

const subHeaderData20 = [
  { label: "Male", align: "center" },
  { label: "Female", align: "center" },
  { label: "Total", align: "center" },
  { label: "Male", align: "center" },
  { label: "Female", align: "center" },
  { label: "Total", align: "center" },
  { label: "Male", align: "center" },
  { label: "Female", align: "center" },
  { label: "Total", align: "center" },
];

const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      <strong>{label}</strong>
    </TableCell>
  </TableRow>
);

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H4 = () => {
  return (
    <div>
      <p><strong>IV-18. Details as of the end of the Financial Year:</strong></p>
      <br/>
      <p>
        <strong>
          {" "}
          a. Employees and workers (including differently abled):
        </strong>
      </p>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData18.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData18.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(7, "Employees")}
            {renderDataRows([
              {
                id: 1,
                label: "Permanent (D)",
                data: [
                  H18Data.permanentEmployeeTotal,
                  H18Data.permanentEmployeeNumberMale,
                  H18Data.permanentEmployeePercentageMale,
                  H18Data.permanentEmployeeNumberFemale,
                  H18Data.permanentEmployeePercentageFemale,
                ],
              },
              {
                id: 2,
                label: "Other than permanent (E)",
                data: [
                  H18Data.otherThanOtherThanPermanentEmployeeTotal,
                  H18Data.otherThanOtherThanPermanentEmployeeNumberMale,
                  H18Data.otherThanOtherThanPermanentEmployeePercentageMale,
                  H18Data.otherThanOtherThanPermanentEmployeeNumberFemale,
                  H18Data.otherThanOtherThanPermanentEmployeePercentageFemale,
                ],
              },
              {
                id: 3,
                label: "Total employees (D + E)",
                data: [
                  H18Data.totalEmployeeTotal,
                  H18Data.totalEmployeeNumberMale,
                  H18Data.totalEmployeePercentageMale,
                  H18Data.totalEmployeeNumberFemale,
                  H18Data.totalEmployeePercentageFemale,
                ],
              },
            ])}
            {renderTableRow(7, "Workers")}
            {renderDataRows([
              {
                id: 4,
                label: "Permanent (F)",
                data: [
                  H18Data.permanentWorkerTotal,
                  H18Data.permanentWorkerNumberMale,
                  H18Data.permanentWorkerPercentageMale,
                  H18Data.permanentWorkerNumberFemale,
                  H18Data.permanentWorkerPercentageFemale,
                ],
              },
              {
                id: 5,
                label: "Other than permanent (G)",
                data: [
                  H18Data.otherThanOtherThanPermanentWorkerTotal,
                  H18Data.otherThanOtherThanPermanentWorkerNumberMale,
                  H18Data.otherThanOtherThanPermanentWorkerPercentageMale,
                  H18Data.otherThanOtherThanPermanentWorkerNumberFemale,
                  H18Data.otherThanOtherThanPermanentWorkerPercentageFemale,
                ],
              },
              {
                id: 6,
                label: "Total workers (F + G)",
                data: [
                  H18Data.totalWorkerTotal,
                  H18Data.totalWorkerNumberMale,
                  H18Data.totalWorkerPercentageMale,
                  H18Data.totalWorkerNumberFemale,
                  H18Data.totalWorkerPercentageFemale,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <br />

      <p><strong>IV-18. Details as of the end of the Financial Year:</strong></p>
      <br/>
      <p>
        <strong>b. Differently abled Employees and workers</strong>
      </p>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData18.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData18.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(7, "Employees")}
            {renderDataRows([
              {
                id: 1,
                label: "Permanent (D)",
                data: [
                  H18Data.permanentDifferentlyAbledEmployeesTotal,
                  H18Data.permanentDifferentlyAbledEmployeesNumberMale,
                  H18Data.permanentDifferentlyAbledEmployeesPercentageMale,
                  H18Data.permanentDifferentlyAbledEmployeesNumberFemale,
                  H18Data.permanentDifferentlyAbledEmployeesPercentageFemale,
                ],
              },
              {
                id: 2,
                label: "Other than permanent (E)",
                data: [
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledEmployeesTotal,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledEmployeesNumberMale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledEmployeesPercentageMale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledEmployeesNumberFemale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledEmployeesPercentageFemale,
                ],
              },
              {
                id: 3,
                label: "Total employees (D + E)",
                data: [
                  H18Data.totalDifferentlyAbledEmployeesTotal,
                  H18Data.totalDifferentlyAbledEmployeesNumberMale,
                  H18Data.totalDifferentlyAbledEmployeesPercentageMale,
                  H18Data.totalDifferentlyAbledEmployeesNumberFemale,
                  H18Data.totalDifferentlyAbledEmployeesPercentageFemale,
                ],
              },
            ])}
            {renderTableRow(7, "Workers")}
            {renderDataRows([
              {
                id: 4,
                label: "Permanent (F)",
                data: [
                  H18Data.permanentDifferentlyAbledWorkerTotal,
                  H18Data.permanentDifferentlyAbledWorkerNumberMale,
                  H18Data.permanentDifferentlyAbledWorkerPercentageMale,
                  H18Data.permanentDifferentlyAbledWorkerNumberFemale,
                  H18Data.permanentDifferentlyAbledWorkerPercentageFemale,
                ],
              },
              {
                id: 5,
                label: "Other than permanent (G)",
                data: [
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledWorkerTotal,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledWorkerNumberMale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledWorkerPercentageMale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledWorkerNumberFemale,
                  H18Data.otherThanOtherThanPermanentDifferentlyAbledWorkerPercentageFemale,
                ],
              },
              {
                id: 6,
                label: "Total workers (F + G)",
                data: [
                  H18Data.totalDifferentlyAbledWorkerTotal,
                  H18Data.totalDifferentlyAbledWorkerNumberMale,
                  H18Data.totalDifferentlyAbledWorkerPercentageMale,
                  H18Data.totalDifferentlyAbledWorkerNumberFemale,
                  H18Data.totalDifferentlyAbledWorkerPercentageFemale,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <p><strong>IV-19. Participation/Inclusion/Representation of women</strong></p>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData19.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData19.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Board of Directors",
                data: [
                  H19Data.boardTotal,
                  H19Data.boardNumberFemale,
                  H19Data.boardPercentageFemale
                ],
              },
              {
                id: 2,
                label: "Key Management Personnel",
                data: [
                  H19Data.kmpTotal,
                  H19Data.kmpNumberFemale,
                  H19Data.kmpPercentageFemale
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

      <br />

      <p><strong>IV-20. Turnover rate for permanent employees and workers</strong></p>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData20.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData20.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Permanent Employees",
                data: [
                  H20Data.permanentEmployeeMale_22_23,
                  H20Data.permanentEmployeeFemale_22_23,
                  H20Data.permanentEmployeeTotal_22_23,
                  H20Data.permanentEmployeeMale_21_22,
                  H20Data.permanentEmployeeFemale_21_22,
                  H20Data.permanentEmployeeTotal_21_22,
                  H20Data.permanentEmployeeMale_20_21,
                  H20Data.permanentEmployeeFemale_20_21,
                  H20Data.permanentEmployeeTotal_20_21,

                ],
              },
              {
                id: 2,
                label: "Permanent Workers",
                data: [
                  H20Data.contractualEmployeeMale_22_23,
                  H20Data.contractualEmployeeFemale_22_23,
                  H20Data.contractualEmployeeTotal_22_23,
                  H20Data.contractualEmployeeMale_21_22,
                  H20Data.contractualEmployeeFemale_21_22,
                  H20Data.contractualEmployeeTotal_21_22,
                  H20Data.contractualEmployeeMale_20_21,
                  H20Data.contractualEmployeeFemale_20_21,
                  H20Data.contractualEmployeeTotal_20_21,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
};

export default H4;
