import { H2Data } from "./data.js";

const H2 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H2Data.isDesignatedConsumer}</p>
    </div>
  );
};

export default H2;
