import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "Category", align: "center", rowSpan: 2 },
  { label: "FY 2022-23", align: "center", colSpan: 3},
  { label: "FY 2021-22", align: "center", colSpan: 3 },
];

const subHeaderData2 = [
  { label: "Filed during the year", align: "center" },
  { label: "Pending resolution at the end of year", align: "center" },
  { label: "Remarks", align: "center" },
  { label: "Filed during the year", align: "center" },
  { label: "Pending resolution at the end of year", align: "center" },
  { label: "Remarks", align: "center" },
];


const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {
        id == 1 ?
          <TableCell rowSpan={6} colSpan={6} align="center">
          {"No complaints have been filed by employees and workers under any of the categories."}
        </TableCell> : ""
      }
    </TableRow>
  ));

const H6 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData2.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Sexual Harassment",
                data: [],
              },
              {
                id: 2,
                label: "Discrimination at workplace",
                data: [],
              },
              {
                id: 3,
                label: "Child Labor",
                data: [],
              },
              {
                id: 4,
                label: "Forced Labor/Involuntary Labor",
                data: [],
              },
              {
                id: 5,
                label: "Wages",
                data: [],
              },
              {
                id: 6,
                label: "Other human rights related issues",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H6;
