export const H1Data = {
    boardTotal: "0",
    boardPrinciple: "NA",
    boardPercentage: "0%",
    kmpTotal: "1 (CFO)",
    kmpPrinciple: "Advance Business Management",
    kmpPercentage: "Negligible%",
    employeeTotal: "15",
    employeePrinciple: "Leadership, Advance Quality & Cost Management, and Marketing",
    employeePercentage: "Negligible%",
    contractualTotal: "Nil",
    contractualPrinciple: "-",
    contractualPercentage: "N.A.",
  };
  
  // make it dash if not applicable
  export const H2Data = {
    monetaryPenaltyPriciple: "",
    monetaryPenaltyInstitution: "",
    monetaryPenaltyAmount: "",
    monetaryPenaltyBrief: "",
    isMonetaryPenaltyAppeal: "",
    monetarySettlementPrinciple: "",
    monetarySettlementInstitution: "",
    monetarySettlementAmount: "",
    monetarySettlementBrief: "",
    isMonetarySettlementAppeal: "",
    monetaryCompoundingPrinciple: "",
    monetaryCompoundingInstitution: "",
    monetaryCompoundingAmount: "",
    monetaryCompoundingBrief: "",
    isMonetaryCompoundingAppeal: "",
    NonMonetaryImprisonmentPrinciple: "",
    NonMonetaryImprisonmentInstitution: "",
    NonMonetaryImprisonmentAmount: "",
    NonMonetaryImprisonmentBrief: "",
    isNonMonetaryImprisonmentAppeal: "",
    NonMonetaryPunishmentPrinciple: "",
    NonMonetaryPunishmentInstitution: "",
    NonMonetaryPunishmentAmount: "",
    NonMonetaryPunishmentBrief: "",
    isNonMonetaryPunishmentAppeal: "",
  };
  
  export const H3Data = [{
    case: "Not Applicable",
    name: "",
  }];
  
  export const H4Data = {
    policyBrief: "Yes, Dixon has implemented an Anti-Corruption and Anti-Bribery Policy to deter any involvement of its personnel, including the Board of Directors, in corrupt practices. This policy extends its scope to encompass third parties, such as intermediaries, consultants, representatives, subcontractors, or any agents representing Dixon. The Company's unequivocal stance against bribery and corruption is prominently emphasized in this policy, meticulously aligned with pertinent domestic and international legal statutes. The policy is made accessible to all employees and is readily available on the Company's website. It is an extensive policy that outlines various forms of bribery and corruption in detail, serving as a clear guideline for the individuals encompassed by the policy. The same is available on the webiste of the Company at https://www.dixoninfo.com/codes-policies.php No disciplinary action was taken by any law enforcement agency for the charges of bribery/ corruption against Directors/KMPs/ employees/workers. There were no instances of corruption/bribery pertaining to Directors, Key Management Personnels, Employees and Workers.",
  };
  
  export const H5Data = {
    director_23: "0",
    kmp_23: "0",
    employee_23: "0",
    worker_23: "0",
    director_22: "0",
    kmp_22: "0",
    employee_22: "0",
    worker_22: "0",
  };
  
  export const H6Data = {
    directorNumber_23: "0",
    directorRemarks_23: "None",
    kmpNumber_23: "0",
    kmpRemarks_23: "None",

    directorNumber_22: "0",
    directorRemarks_22: "None",
    kmpNumber_22: "0",
    kmpRemarks_22: "None",
  };
  
  export const H7Data = {
    correctiveAction: "As there have been no complaints filed against the Board of Directors, KMPs, Senior Management Employees, and other Company workers, no remedial actions were needed to be taken.",
  };
  

  //skip if not applicable
  export const H8Data = [{
    totalAwareness: "",
    topic: "",
    percentage: "",
  }];
  
  export const H9Data = {
    processDetails: "",
  };
  