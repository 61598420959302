import { H22Data } from "./data.js";

const H6 = () => {
  return (
    <div>
      <p>
        <strong>  VI-22. (i). Is CSR applicable as per Section 135 of the Companies Act, 2013 (Yes/No)? </strong>
      </p>
      <p>{H22Data.iscsrApplicable}</p>
      <br />
      <p> <strong>VI-22. (ii). Revenue from Operations (in Rs) - </strong>{H22Data.turnover}</p>
      <br />
      <p> <strong>VI-22. (iii). Net worth (in Rs.) - </strong>{H22Data.netWorth} </p>
      <br />
    </div>
  );
};

export default H6;
