import BootstrapTable from "react-bootstrap-table-next";
import {
  H16Data,
  H17Data,
} from "./data.js";

const H3 = () => {

 
  const columnsTab1 = [
    {
      dataField: "id",
      text: "S No",
      classes: "table-cell-border-top",
      headerStyle: { width: "8%",},
      headerClasses: "secondry-color-background font-white border-top-left-radius "
    },
    {
      dataField: "location",
      text: "Location",
      classes: "table-cell-border-top",
      headerStyle: { width: "25%"},
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "plants",
      text: "Number of Plants",
      classes: "table-cell-border-top",
      headerStyle: { width: "25%"},
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "office",
      text: "Number of Offices",
      classes: "table-cell-border-top",
      headerStyle: { width: "25%"},
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "total",
      text: "Total",
      classes: "table-cell-border-top",
      headerStyle: { width: "25%"},
      headerClasses: "secondry-color-background font-white border-top-right-radius"
    },
  ];

  const columnsTab2 = [
    {
      dataField: "id",
      text: "S No",
      classes: "table-cell-border-top",
      headerStyle: { width: "8%",},
      headerClasses: "secondry-color-background font-white border-top-left-radius "
    },
    {
      dataField: "location",
      text: "Location",
      classes: "table-cell-border-top",
      headerStyle: { width: "20%"},
      headerClasses: "secondry-color-background font-white"
    },
    {
      dataField: "number",
      text: "Number of Plants",
      classes: "table-cell-border-top",
      headerStyle: { width: "75%"},
      headerClasses: "secondry-color-background font-white border-top-right-radius"
    },
  ];

  return (
    <div>
      <p>
        III-16. Number of locations where plants and/or operations/offices of
        the entity are situated:
      </p>
      <BootstrapTable
        keyField="id"
        classes="column-style"
        data={[
          {
            id: 1,
            location: "National",
            plants: H16Data.nationalPlant,
            office: H16Data.nationalOffice,
            total: H16Data.nationalTotal,
          },
          {
            id: 2,
            location: "International",
            plants: H16Data.internationalPlant,
            office: H16Data.internationalOffice,
            total: H16Data.internationalTotal,
          },
        ]}
        bordered={false}
        columns={columnsTab1}
      />
      <br />
      <p>III-17. Markets served by the entity:</p>
      
      <p><strong>a. Number of locations</strong></p>
      <BootstrapTable
        keyField="id"
        classes="column-style"
        data={[
          {
            id: 1,
            location: "National",
            number: H17Data.national
          },
          {
            id: 2,
            location: "International",
            number: H17Data.international
          },
        ]}
        bordered={false}
        columns={columnsTab2}
      />

      <br />
      <p><strong>b. What is the contribution of exports as a percentage of the total turnover of the entity?</strong></p>
      <p>Export constitute 0.10% of the total turnover of the Company</p>

      <br />
      <p><strong>c. A brief on types of customers</strong></p>
      <p>Dixon has a wide range of products and has built an unrivalled market dominance in the electronics manufacturing and design industry over the years. We operate on a B2B business model. Our offerings have been mapped product-wise here:</p>
      <ol>
        <p style={{display: "inline-flex"}}><li style={{fontWeight: 700, paddingRight: "8px"}}>Consumer Electronics:</li>The major customers in this segment are Xiaomi, Samsung, Hisense, VU, Nokia, Panasonic, TCL, Lloyd, Flipkart, Philips etc.</p>
        <p style={{display: "inline-flex"}}><li style={{fontWeight: 700, paddingRight: "8px"}}>Lighting Products:</li>The major customers in this segment are Signify, Panasonic, Wipro, Bajaj, Syska, Orient, Polycab, Luminous, Crompton etc.</p>
        <p style={{display: "inline-flex"}}><li style={{fontWeight: 700, paddingRight: "8px"}}>Home Appliances:</li>The major customers in this segment are Samsung, Bosch, Godrej, Voltas- Beko, Panasonic, Lloyd, Flipkart, Haier, Reliance etc.</p>
        <p style={{display: "inline-flex"}}><li style={{fontWeight: 700, paddingRight: "8px"}}>Mobile Phones, EMS & Others:</li>The major customers in this segment include Samsung, Acer etc. </p>
      </ol> 
    </div>
  );
};

export default H3;
