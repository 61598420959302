import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "", rowSpan: 2, colWidth: "10%" },
  { label: "P1", rowSpan: 2 },
  { label: "P2", rowSpan: 2 },
  { label: "P3", rowSpan: 2 },
  { label: "P4", rowSpan: 2 },
  { label: "P5", rowSpan: 2 },
  { label: "P6", rowSpan: 2 },
  { label: "P7", rowSpan: 2 },
  { label: "P8", rowSpan: 2 },
  { label: "P9", rowSpan: 2 },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell align="left">{label}</TableCell>
      {id == 1 ? (
        <TableCell rowSpan={5} colSpan={9} align="center">
          {
            "No, The Company has various policies in place which are reviewed periodically by the Board and it's Committees."
          }
        </TableCell>
      ) : (
        ""
      )}
    </TableRow>
  ));

const H3 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(
                ({ label, align, rowSpan, colSpan, colWidth }, index) => (
                  <TableCell
                    key={index}
                    align={align}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    colWidth={colWidth}
                  >
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label:
                  "Has the entity carried out independent assessment/evaluation of the working of its policies by an external agency?",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H3;
