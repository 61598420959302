import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "% Of your plants and offices that were assessed (by entity or statutory authorities or third parties)", align: "center", colSpan: 3},
];


const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {
        id == 1 ?
          <TableCell rowSpan={6} colSpan={1} align="center">
          {"100%"}
        </TableCell> : ""
      }
    </TableRow>
  ));

const H6 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Child labor",
                data: [],
              },
              {
                id: 2,
                label: "Forced/involuntary labor",
                data: [],
              },
              {
                id: 3,
                label: "Sexual harassment",
                data: [],
              },
              {
                id: 4,
                label: "Discrimination at workplace",
                data: [],
              },
              {
                id: 5,
                label: "Wages",
                data: [],
              },
              {
                id: 6,
                label: "Other",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H6;
