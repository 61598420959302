import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H2Data } from "./data.js";

const headerData1 = [
  { label: "S. No", align: "center" },
  { label: "Name of Project for which R&R is ongoing", align: "center"},
  { label: "State", align: "center"},
  { label: "District", align: "center"},
  { label: "No. of Project Affected Families (PAFs)", align: "center"},
  { label: "% Of PAFs covered by R&R ", align: "center"},
  { label: "Amounts paid to PAFs in the 2022-23 (In H)", align: "center"},

];

const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      {label}
    </TableCell>
  </TableRow>
);

const H2 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(7, H2Data.serial)}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H2;
