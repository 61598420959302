export const H1Data = {
  number:  "6",
};

export const H2Data = [
  {
    serial: 1,
  name: "Confederation of Indian Industry (CII)",
    reach: "National",
  },
  {
    serial: 2,
    name: "Electronic Industries Association of India (ELCINA)",
    reach: "National",
  },
  {
    serial: 3,
    name: "Electric Lamp and Component Manufacturers (ELCOMA)",
    reach: "National",
  },
  {
    serial: 4,
    name: "India Cellular & Electronics Association (ICEA)",
    reach: "National",
  },
  {
    serial: 5,
    name: "Federation of Indian Chambers of Commerce & Industry (FICCI)",
    reach: "National",
  },
  {
    serial: 6,
    name: "Consumer Electronics and Appliances Manufacturers Association (CEAMA)",
    reach: "National",
  },
];

export const H3Data = {
  authority:
    "Not applicable, since Company has not engaged in any anti-competitive activities. Company believes in conducting business with integrity by following the fair means. Therefore, neither it promotes not engage in any behaviour resulting into anti-competitive activities, Since no such cases has been filed against the Company, no corrective actions were needed to be taken.",
  brief: "",
  correctiveAction: "",
};

export const H4Data = {
  serial: "",
  isPublic: "",
  policy: "",
  frequency: "",
  methods: "",
  weblink: "",
};
