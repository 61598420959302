export const H1Data = {
  project: "Not Applicable",
  isIndependent: "",
  notification: "",
  date: "",
  isResult: "",
  link: "",
};

export const H2Data = {
  serial: "Not Applicable",
  name: "",
  state: "",
  district: "",
  affected: "",
  covered: "",
  amount: "",
};

export const H3Data = {
  mechanism: "The Company engages with the Community as a part of our CSR activities to address their concerns and needs. The mode of engagement with the Community are CSR projects, surveys etc",
};

export const H4Data = {
  msme23: "Not mapped at present",
  neigbouring23: "",
  msme22: "Not mapped at present",
  neigbouring22: "",
  variable: "Currently, the Company does not measure the procurement spend on MSMEs and local sourcing. However, it is in the process of developing the system for the same.",
};

export const H5Data = {
  negative: "",
  corrective: "",
};

export const H6Data = {
  serial: 0,
  state: "",
  distrit: "",
  amount: 0,
};

export const H7Data = {
  isPolicy: "",
  group: "",
  percentageSourcing: "",
};

export const H8Data = {
  ipr: "",
  isOwned: "",
  isShared: "",
  basis: "",
};

export const H9Data = {
  authority: "",
  brief: "",
  corrective: "",
};

export const H10Data = {
  serial: 0,
  csr: "",
  person: 0,
};
