import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P4Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P4/H1';
import H2 from './P4/H2';
import H3 from './P4/H3';
import H4 from './P4/H4';
import H5 from './P4/H5';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SelfImprovementOutlinedIcon from '@mui/icons-material/SelfImprovementOutlined';
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import InterpreterModeOutlinedIcon from '@mui/icons-material/InterpreterModeOutlined';

const { Panel } = Collapse;

const Principle4 = () => {

  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P4Heading[0].description:
        return <H1 />;
      case P4Heading[1].description:
        return <H2 />;
      default:
        return <H1 />;
    }

  }

  const highlight = [
    { name: "We work to foster a good relationship with the Company and employees which helps in promoting employee well-being, performance, career development of the employees", icon: <SelfImprovementOutlinedIcon style={{ fontSize: "42px" }} />, value: "Employees" },
    { name: "Quarterly board meeting are conducted", icon: <MeetingRoomOutlinedIcon style={{ fontSize: "42px" }} />, value: "Directors" },
    { name: "We enable the social wellbeing of the Community members and establish stronger bond", icon: <SelfImprovementOutlinedIcon style={{ fontSize: "42px" }} />, value: "Community" },
    { name: "Regular interactions with them enables us to address their queries and issues in effective and timely manner and build stronger relationships", icon: <InterpreterModeOutlinedIcon style={{ fontSize: "42px" }} />, value: "Customers & Suppliers" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="" onClose={onClose} open={open}>
        <p>Dixon Technologies tracks, maintains, and promotes its internal and external stakeholders' interests across its value chain.
The Company continuously engages and communicates with all of its stakeholders, including employees, workers, customers,
suppliers, investors, shareholders, retailers, civil society organisations, and local communities in areas where the Company
works.</p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[5].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[5].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[1]}
            ghost
          >
            {
              P4Heading.map((data, index) => {
                if(index<2) {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
      <br/>
    </div>
  );
};
export default Principle4;