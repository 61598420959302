import { Card, Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BRSRSchema } from '../constants/Principle';
import { convertPrincipleTileToUrlName } from '../utility/Common';
import p1 from '../assets/p1.png';
import p2 from '../assets/p2.png';
import p3 from '../assets/p3.png';
import p4 from '../assets/p4.png';
import p5 from '../assets/p5.png';
import p6 from '../assets/p6.png';
import p7 from '../assets/p7.png';
import p8 from '../assets/p8.png';
import p9 from '../assets/p9.png';
import s1 from '../assets/sa.png';
import s2 from '../assets/sb.png';


const AllPrinciple = () => {

  const navigate = useNavigate();

  const OnCardSelect = (title) => {
    const urlkey = convertPrincipleTileToUrlName(title);
    navigate('/'+urlkey)
  }

  const getImagePath = (data) => {
    switch (data.title){
      case "Section A":
        return s1;
      case "Section B":
        return s2; 
      case "Principle 1":
        return p1; 
      case "Principle 2":
        return p2; 
      case "Principle 3":
        return p3; 
      case "Principle 4":
        return p4; 
      case "Principle 5":
        return p5; 
      case "Principle 6":
        return p6; 
      case "Principle 7":
        return p7; 
      case "Principle 8":
        return p8; 
      case "Principle 9":
        return p9; 
      default :
        return p1;
    }
  }

  return (<div style={{ padding: "50px" }}>
    <Row  gutter={[0, 24]}>
      {
        BRSRSchema.map(data => {
          return <Col
            xs={24} sm={24} md={12} lg={8} xl={6} xxl={4}
            style={{
              padding: "10px"
            }}
          >
            <Card
              hoverable
              style={{
                height: "100%",
                width: "280px"
              }}
              onClick={() => OnCardSelect(data.title)}
              cover={
                <img
                  height={"140px"}
                  alt={data.title}
                  src={getImagePath(data)}
                />
              }
            >
              <h4>{data.title}</h4>
              <p style={{ paddingTop: "8px", fontSize: "12px" }}>{data.description}</p>
            </Card>
          </Col>
        })
      }
    </Row>
  </div>)
}


export default AllPrinciple;