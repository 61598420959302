import { H3Data } from "./data.js";

const H2 = () => {
  return (
    <div className="text-padding-left">
      <p>
       {H3Data.authority}
      </p>
    </div>
  );
};

export default H2;
