import { Col, Row } from 'antd';
import CardMedia from '@mui/material/CardMedia';
import demoPerson from '../assets/person.png';
import { appTheme } from '../config';

export const convertPrincipleTileToUrlName = (title) => {
    return title?.replace(/\s+/g, '-').toLowerCase();
}

export const leadershipMessageComponent = () => {
    return <Row style={{ paddingBottom: "60px", paddingTop: "32px", paddingLeft: "20%", paddingRight: "20%" }}>
    <Col xs={24} sm={24} md={12} lg={12}  >
        <CardMedia
          component="img"
          sx={{ maxWidth: "320px", maxHeight: "320px" }}
          image={demoPerson}
        />
    </Col>
    <Col xs={24} sm={24} md={12} lg={12} >
      <div style={{paddingLeft: "28px"}}>
        <h3 style={{color: appTheme.colors.primary}}> Message from Our Leaders</h3>
        <p style={{fontSize: "16px"}}>
          "I would also like to take this opportunity to appreciate each and every employee of Dixon. It is because of their efforts we continue to be one of the leading EMS players in India."
        </p>
        <br></br>
        <h4 style={{color: appTheme.colors.primary}}> Ashish Kumar</h4>
        <p style={{fontSize: "16px"}}> Executive Director, Legal & Corporate Affairs and Company Secretary </p>
      </div>
    </Col>
  </Row>
}

export const generalDislosureComponent = (highlight) => {
  return <Col
        style={{
          background: appTheme.colors.secondry,
          borderRadius: "10px",
          color: appTheme.fontColor.light,
          marginBottom: "40px",
        }}
        offset={1} span={22}
      >
        <h2 style={{ padding: "10px", paddingLeft: "20px" }}> Highlights </h2>


        <Row>
          {highlight.map((item, index) => (
            <Col xs={12} sm={8} md={8} lg={8} xl={8} xxl={8}>
              <div
                style={{
                  display: "inline-flex",
                  paddingLeft: "20%",
                  paddingRight: "10%",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                }}
              >
                <div style={{ paddingRight: "10px" }}>{item.icon}</div>
                <div>
                  <p
                    style={{
                      fontSize: "24px",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {item.value}
                  </p>
                  <p
                    style={{
                      fontSize: "12px",
                      marginBottom: "0px",
                      paddingBottom: "0px",
                    }}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            </Col>
          ))}</Row>
      </Col>
}