import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H6Data } from "./data.js";

const headerData5 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "Unit", align: "center"},
  { label: "FY 2022-23", align: "center"},
  { label: "FY 2021-22", align: "center"},
];

const renderDataRows = (data) =>
  data.map(({ id, label, data, bold }) => (
    <TableRow key={id}>
      <TableCell style={{fontWeight: bold ? "bold" : "normal"}}>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell style={{fontWeight: bold ? "bold" : "normal"}} key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H6 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H6Data.variable}</p>
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData5.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Total Scope 1 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
                data: [
                  "tCO2e",
                  H6Data.scope1_23,
                  H6Data.scope1_22
                ],
              },
              {
                id: 2,
                label: "Total Scope 2 emissions (Break-up of the GHG into CO2, CH4, N2O, HFCs, PFCs, SF6, NF3, if available)",
                data: [
                  "tCO2e",
                  H6Data.scope2_23,
                  H6Data.scope2_22
                ],
              },
              {
                id: 3,
                label: "Total Scope 1 and Scope 2 emissions per rupee of turnover (tCO2e/ H Crore)",
                data: [
                  "tCO2e",
                  H6Data.total_23,
                  H6Data.total_22
                ],
                bold: true
              },
              {
                id: 4,
                label: "Total Scope 1 and Scope 2 emission intensity (optional) – the relevant metric may be selected by the entity",
                data: [
                  "-",
                  "-",
                  "-"
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <p>{H6Data.variable}</p>
      <br></br>
      <p> Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency-No </p>
    </div>
  );
};

export default H6;
