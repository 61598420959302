import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData18 = [
  { label: "Category", rowSpan: 2 },
  { label: "FY 2022-23", align: "center", colSpan: 3 },
  { label: "FY 2021-22", align: "center", colSpan: 3 },
];

const subHeaderData18 = [
  { label: "Total (A)", align: "center" },
  { label: "No. of employees / workers covered (B)", align: "center" },
  { label: "% (B/A)", align: "center" },
  { label: "Total (C)", align: "center" },
  { label: "No. of employees / workers covered (D)", align: "center" },
  { label: "% (C/D)", align: "center" },
];


const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      <strong>{label}</strong>
    </TableCell>
  </TableRow>
);

const H1 = () => {
  return (
    <div>

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData18.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData18.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(5, "Employees")}
            <TableRow >
              <TableCell>{"Permanent"}</TableCell>
              <TableCell colSpan={3} rowSpan={3} align="center">{"Nil"}</TableCell>
              <TableCell colSpan={3} rowSpan={3} align="center">{"Nil"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>{"Other than Permanent"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>{"Total Employees"}</TableCell>
            </TableRow>
            {renderTableRow(5, "Workers")}
            <TableRow >
              <TableCell>{"Permanent"}</TableCell>
              <TableCell colSpan={3} rowSpan={3} align="center">{"Nil"}</TableCell>
              <TableCell colSpan={3} rowSpan={3} align="center">{"Nil"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>{"Other than Permanent"}</TableCell>
            </TableRow>
            <TableRow >
              <TableCell>{"Total Workers"}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H1;
