import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H1Data } from "./data.js";

const headerData1 = [
  { label: "Category", align: "center", rowSpan: 2 },
  { label: "Unit", align: "center"},
  { label: "FY 2022-23", align: "center"},
  { label: "FY 2021-22", align: "center"},
];

const renderDataRows = (data) =>
  data.map(({ id, label, data, bold }) => (
    <TableRow key={id}>
      <TableCell style={{fontWeight: bold ? "bold" : "normal"}}>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell style={{fontWeight: bold ? "bold" : "normal"}} key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H1 = () => {
  return (
    <div className="text-padding-left ">
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Total electricity consumption (A)",
                data: [
                  "TJ",
                  H1Data.electicictyConsumption23,
                  H1Data.electicictyConsumption22
                ],
              },
              {
                id: 2,
                label: "Total fuel consumption (B)",
                data: [
                  "TJ",
                  H1Data.fuelConsumption23,
                  H1Data.fuelConsumption22
                ],
              },
              {
                id: 3,
                label: "Energy consumption through other sources (C)",
                data: [
                  "TJ",
                  H1Data.energyOtherSource23,
                  H1Data.energyOtherSource22
                ],
              },
              {
                id: 4,
                label: "Total energy consumption (A+B+C)",
                data: [
                  "TJ",
                  H1Data.totalEnergy23,
                  H1Data.totalEnergy22
                ],
                bold: true
              },
              {
                id: 5,
                label: "Energy intensity per rupee of turnover (Total energy consumption/ turnover in rupees)",
                data: [
                  "TJ",
                  H1Data.energyIntesity23,
                  H1Data.energyIntesity22
                ],
              },
              {
                id: 6,
                label: "Energy intensity (optional) – the relevant metric may be selected by the entity",
                data: [
                  "TJ",
                  H1Data.energyIntensityOptional23,
                  H1Data.energyIntensityOptional22
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <p>{H1Data.variable}</p>
      <br></br>
      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency- No</p>
    </div>
  );
};

export default H1;
