export const H1Data = {
  cin: "L32101UP1993PLC066581",
};

export const H2Data = {
  name: "Dixon Technologies (India) Limited",
};

export const H3Data = {
  incorporation: "1993",
};

export const H4Data = {
  registeredOffice:
    "B-14 & 15, Phase-2, District Gautam Buddha Nagar, Noida-201305",
};

export const H5Data = {
  address: "B-14 & 15, Phase-2, District Gautam Buddha Nagar, Noida-201305",
};

export const H6Data = {
  email: "investorrelations@dixoninfo.com",
};

export const H7Data = {
  telephone: "0120-4737200",
};

export const H8Data = {
  website: "dixoninfo.com",
};

export const H9Data = {
  financialYear: "01-04-2022 to 31-03-2023",
};

export const H10Data = {
  exchange: "BSE Limited & National Stock Exchange of India Limited",
};

export const H11Data = {
  capital: "Rs. 11,91,20,330",
};

export const H12Data = {
  name: "Ashish Kumar (Chief Legal Counsel & Group CS)",
  mobile: "0120-4737200",
  email: "investorrelations@dixoninfo.com",
};

export const H13Data = {
  boundary:
    "The financial disclosures made in this report are on a standalone basis.",
};

export const H14Data = [
  {
    mainActivity: "Manufacturing and Design",
    business:
      "Consumer Electronics: Under the consumer electronics segment, Dixon primarily designs and manufactures LED TV and PCB",
    turnover: "62%",
  },
  {
    mainActivity: "Manufacturing and Design",
    business:
      "Lighting: Company designs and manufactures lighting products like LED lights, tube lights, Indoor and Outdoor drivers",
    turnover: "15%",
  },
  {
    mainActivity: "Manufacturing and Design",
    business:
      "Home Appliances: Company designs and manufactures semi and fully automatic washing machines. Under this product segment, fully ODM (Original Design Manufacturing) model is followed.",
    turnover: "16%",
  },
  {
    mainActivity: "Manufacturing and Design",
    business:
      "Mobile Phone & EMS division & Others: Manufactures mobile phones and the associated hearables and wearables and laptops for the globally recognized brands.",
    turnover: "5%",
  },
];

export const H15Data = [
  {
    product: "Consumer Electronics",
    nic: "26301",
    turnover: "62%",
  },
  {
    product: "Lighting",
    nic: "27400",
    turnover: "15%",
  },
  {
    product: "Home Appliances",
    nic: "27501",
    turnover: "16%",
  },
  {
    product: "Mobile & EMS Division & Others",
    nic: "26305",
    turnover: "5%",
  },
];

export const H16Data = {
  nationalPlant: "13",
  nationalOffice: "1*",
  nationalTotal: "14",
  internationalPlant: "0",
  internationalOffice: "1**",
  internationalTotal: "1",
};

export const H17Data = {
  national:
    "The Company sells its products in all the 28 states and 8 Union territories in the country. (The Company supplies the products to respective brand owners who further distribute the products in the markets to the end customers)",
  international:
    "The Company services in approx 10 countries across the globe.",
};

export const H18Data = {
  permanentEmployeeTotal: "1551",
  permanentEmployeeNumberMale: "1491",
  permanentEmployeePercentageMale: "96.13%",
  permanentEmployeeNumberFemale: "60",
  permanentEmployeePercentageFemale: "3.86%",
  otherThanOtherThanPermanentEmployeeTotal: "0",
  otherThanOtherThanPermanentEmployeeNumberMale: "0",
  otherThanOtherThanPermanentEmployeePercentageMale: "0%",
  otherThanOtherThanPermanentEmployeeNumberFemale: "0",
  otherThanOtherThanPermanentEmployeePercentageFemale: "0%",
  totalEmployeeTotal: "1551",
  totalEmployeeNumberMale: "1491",
  totalEmployeePercentageMale: "96.13%",
  totalEmployeeNumberFemale: "60",
  totalEmployeePercentageFemale: "3.86%",
  permanentWorkerTotal: "206",
  permanentWorkerNumberMale: "203",
  permanentWorkerPercentageMale: "98.54%",
  permanentWorkerNumberFemale: "3",
  permanentWorkerPercentageFemale: "1.46%",
  otherThanOtherThanPermanentWorkerTotal: "11000",
  otherThanOtherThanPermanentWorkerNumberMale: "7150",
  otherThanOtherThanPermanentWorkerPercentageMale: "65%",
  otherThanOtherThanPermanentWorkerNumberFemale: "3850",
  otherThanOtherThanPermanentWorkerPercentageFemale: "35%",
  totalWorkerTotal: "11206",
  totalWorkerNumberMale: "7353",
  totalWorkerPercentageMale: "65.61%",
  totalWorkerNumberFemale: "3853",
  totalWorkerPercentageFemale: "34.38%",

  permanentDifferentlyAbledEmployeesTotal: "0",
  permanentDifferentlyAbledEmployeesNumberMale: "0",
  permanentDifferentlyAbledEmployeesPercentageMale: "0",
  permanentDifferentlyAbledEmployeesNumberFemale: "0",
  permanentDifferentlyAbledEmployeesPercentageFemale: "0",
  otherThanOtherThanPermanentDifferentlyAbledEmployeesTotal: "0",
  otherThanOtherThanPermanentDifferentlyAbledEmployeesNumberMale: "0",
  otherThanOtherThanPermanentDifferentlyAbledEmployeesPercentageMale: "0",
  otherThanOtherThanPermanentDifferentlyAbledEmployeesNumberFemale: "0",
  otherThanOtherThanPermanentDifferentlyAbledEmployeesPercentageFemale: "0",
  totalDifferentlyAbledEmployeesTotal: "0",
  totalDifferentlyAbledEmployeesNumberMale: "0",
  totalDifferentlyAbledEmployeesPercentageMale: "0%",
  totalDifferentlyAbledEmployeesNumberFemale: "0",
  totalDifferentlyAbledEmployeesPercentageFemale: "0%",
  permanentDifferentlyAbledWorkerTotal: "0",
  permanentDifferentlyAbledWorkerNumberMale: "0",
  permanentDifferentlyAbledWorkerPercentageMale: "0%",
  permanentDifferentlyAbledWorkerNumberFemale: "0",
  permanentDifferentlyAbledWorkerPercentageFemale: "0%",
  otherThanOtherThanPermanentDifferentlyAbledWorkerTotal: "0",
  otherThanOtherThanPermanentDifferentlyAbledWorkerNumberMale: "0",
  otherThanOtherThanPermanentDifferentlyAbledWorkerPercentageMale: "0%",
  otherThanOtherThanPermanentDifferentlyAbledWorkerNumberFemale: "0",
  otherThanOtherThanPermanentDifferentlyAbledWorkerPercentageFemale: "0%",
  totalDifferentlyAbledWorkerTotal: "0",
  totalDifferentlyAbledWorkerNumberMale: "0",
  totalDifferentlyAbledWorkerPercentageMale: "0%",
  totalDifferentlyAbledWorkerNumberFemale: "0",
  totalDifferentlyAbledWorkerPercentageFemale: "0%",
};

export const H19Data = {
  boardTotal: "7",
  boardNumberFemale: "1",
  boardPercentageFemale: "14.28%",
  kmpTotal: "4",
  kmpNumberFemale: "0",
  kmpPercentageFemale: "0%",
};

export const H20Data = {
  permanentEmployeeMale_22_23: "18%",
  permanentEmployeeFemale_22_23: "25%",
  permanentEmployeeTotal_22_23: "20%",
  contractualEmployeeMale_22_23: "13%",
  contractualEmployeeFemale_22_23: "8%",
  contractualEmployeeTotal_22_23: "11%",

  permanentEmployeeMale_21_22: "14%",
  permanentEmployeeFemale_21_22: "19%",
  permanentEmployeeTotal_21_22: "15%",
  contractualEmployeeMale_21_22: "13%",
  contractualEmployeeFemale_21_22: "4%",
  contractualEmployeeTotal_21_22: "11%",

  permanentEmployeeMale_20_21: "22%",
  permanentEmployeeFemale_20_21: "10%",
  permanentEmployeeTotal_20_21: "19%",
  contractualEmployeeMale_20_21: "41%",
  contractualEmployeeFemale_20_21: "27%",
  contractualEmployeeTotal_20_21: "39%",
};

export const H21Data = [
  {
    company: "Dixon Global Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "100%",
    isparticipate: "No",
  },
  {
    company: "Padget Electronics Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "100%",
    isparticipate: "Yes",
  },
  {
    company: "AIL Dixon Technologies Private Limited",
    typeOfVenture: "Joint Venture",
    shares: "50%",
    isparticipate: "Yes",
  },
  {
    company: "Dixon Electro Appliances Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "51%",
    isparticipate: "No",
  },
  {
    company: "Dixon Electro Manufacturing Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "100%",
    isparticipate: "No",
  },
  {
    company: "Dixon Technologies Solutions Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "100%",
    isparticipate: "No",
  },
  {
    company: "Rexxam Dixon Electronics Private Limited",
    typeOfVenture: "Joint Venture",
    shares: "40%",
    isparticipate: "No",
  },
  {
    company: "Califonix Tech and Manufacturing Private Limited",
    typeOfVenture: "Joint Venture",
    shares: "50%",
    isparticipate: "No",
  },
  {
    company: "Dixtel Communications Private Limited",
    typeOfVenture: "Subsidiary",
    shares: "100%",
    isparticipate: "No",
  },
];

export const H22Data = {
  iscsrApplicable:
    " Yes, CSR is applicable to the Company as per section 135 of Companies Act, 2013 and rules made thereunder.",
  turnover: "6,99,740 Lakhs",
  netWorth: "1,16,640 Lakhs",
};

export const H23Data = {
  communitiesLink:
    "Yes, the Company is having grievance redressal mechanism in place. Investors relations at <a href= `https://www.dixoninfo.com`>www.dixoninfo.com</a><br/> There were no grievances registered from the involved communities throughout the fiscal year.Dixon's objective is to enhance the value of the communities in and around which they operate. They achieve this goal by executing CSR projects and community engagement initiatives. These efforts encompass strategies such as organizing Focus Group Discussions (GDs) and conducting individual interactions. Additionally, if any community member encounters a concern, they have the option to contact the Chief Human Resource Officer of the Company",
  communitiesFiled_23: "0",
  communitiesPending_23: "0",
  comminitiesRemark_23: "-",
  communitiesFiled_22: "0",
  communitiesPending_22: "0",
  comminitiesRemark_22: "-",
  investorsLink:
    "Yes, The Company is having grievances redressal mechanism in place. Investor Relations at <a href= `https://www.dixoninfo.com`>www.dixoninfo.com</a><br/> No grievances were reported from the investors throughout the financial year. Dixon employs a variety of channels to enhance value creation for investors. These include Annual General Meetings (AGMs), disclosures provided in quarterly and annual reports, Investor calls held quarterly/ half-yearly/ annually, and informative presentations. A continuous dialogue with investors is maintained to gather feedback, comments, and engage in discussions about the company's performance. To facilitate feedback on the company and its operations, Dixon offers a survey accessible on its official website. Additionally, a dedicated investor relations team is tasked with addressing the concerns and queries of investors. Should investors have any inquiries regarding financial matters, they are welcome to directly correspond with the Chief Financial Officer (CFO) of the Company.",
  investorsFiles_23: "0",
  investorsPending_23: "0",
  investorsRemark_23: "-",
  investorsFiles_22: "0",
  investorsPending_22: "0",
  investorsRemark_22: "-",
  shareholderLink: "Yes, the Company has a redressal mechanism in place. Investors relations at <a href= `https://www.dixoninfo.com`>www.dixoninfo.com</a><br/> Throughout the year, a total of 13 complaints were received from shareholders, and each of these concerns was promptly and effectively resolved. The Company adheres to a structured process for handling shareholder grievances. An Annual General Meeting (AGM) is held, providing shareholders with an annual platform to discuss any issues or queries they may have. During this gathering, attending shareholders have the opportunity to raise their concerns, and the Company ensures swift and appropriate actions to address and resolve these matters. Additionally, shareholders are offered a means to express their feedback through a survey available on the company's website, providing insights into the company's operations. The Stakeholders Relationship Committee is tasked with reviewing stakeholder complaints during their quarterly committee meetings. Furthermore, the investor relations team is responsible for tackling shareholder queries and issues. Shareholders also have the option to directly communicate their queries and complaints to the Chief Financial Officer (CFO) or the Chief Legal Counsel and Group CS for swift resolution.",
  shareholderFiles_23: "13",
  shareholderPending_23: "0",
  shareholderRemark_23: "-",
  shareholderFiles_22: "44",
  shareholderPending_22: "0",
  shareholderRemark_22: "-",
  employeeLink: "Yes, the Company is having grievance redressal mechanism in place wherein employees and workers can directly reach out to HR dept at their respective units. Throughout the fiscal year, no employee or worker complaints were reported. Dixon emphasizes their well-being via Employee Engagement Initiatives, Satisfaction Surveys, and Training & Development Programs",
  employeeFiled_23: "0",
  employeePending_23: "0",
  employeeRemark_23: "-",
  employeeFiled_22: "0",
  employeePending_22: "0",
  employeeRemark_22: "-",
  customerLink: "Yes, the Company is having grievance redressal mechanism in place at <a href= `https://www.dixoninfo.com`>www.dixoninfo.com</a><br/> There were no customer complaints in the past financial year. Dixon operates on a B2B model, engaging business clients through personalized interactions, meetings, Feedback Mechanism Surveys, and product delivery evaluations. Queries and concerns from customers are handled by the purchase/sales team.",
  customerFiled_23: "0",
  customerPending_23: "0",
  customerRemark_23: "-",
  customerFiled_22: "0",
  customerPending_22: "0",
  customerRemark_22: "-",
  partnerLink: "Yes, the Company is having grievance redressal mechanism in place. Investor relations at <a href= `https://www.dixoninfo.com`>www.dixoninfo.com</a><br/> No concerns were raised by value chain partners in the financial year. Dixon maintains connections with its partners via site visits, personal interactions, and surveys. The purchase/sales team, responsible for partner engagement, handles queries, while periodic reviews and feedback ensure information exchange.",
  partnerFiled_23: "0",
  partnerPending_23: "0",
  partnerRemark_23: "-",
  partnerFiled_22: "0",
  partnerPending_22: "0",
  partnerRemark_22: "-",
  otherLink: "No",
  otherFiled_23: "0",
  otherPendinng_23: "0",
  otherRemark_23: "-",
  otherFiled_22: "0",
  otherPendinng_22: "0",
  otherRemark_22: "-",
};

export const H24Data = [{
    materialIssue: "Human Capital Development",
    riskOpportunity: "Opportunity",
    rationale: "From an opportunity perspective, Human Capital stands as a pivotal asset for the Company, especially within the rapidly evolving electronics industry. Amidst the dynamic changes and growth spurred by emerging and existing technologies, the Company's skilled workforce is key to remaining adaptable and relevant. This proficient labor force plays a vital role in ensuring the Company's sustainable growth. The implementation of skilling and engagement programs not only fosters holistic employee development but also secures their tenure. The Company's strategic approach involves both shortterm initiatives, including induction, skill enhancement, On the Job trainings, and technical training, and long-term aspirations, culminating in a cutting-edge Centre of Excellence (COE) that nurtures theoretical and practical learning. In terms of recognition, the Company has proudly held the Great Place to Work (GWP) certification for the past two years, underscoring a remarkable organizational culture upheld by its employees. This certification serves as a testament to the Company's foundation of trust, fairness, respect, and camaraderie – the very essence of a Great Workplace.",
    mitigate: "The Company is wellprepared to address this without risk, given its implementation of diverse employee-centric initiatives, including skill development, training, and various engagement programs.",
    implication: "Positive Implications Focusing on human capital advancement will yield favorable financial outcomes for the organization. Investing in employee well-being initiatives, providing competitive compensation, and offering benefits foster a robust Companyemployee relationship. This enhances retention rates, amplifies productivity, and elevates the Company's brand image.",
  },
  {
    materialIssue: "Health and safety",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: Ensuring the well-being of the workforce is imperative within the workplace. Given the Company's presence in the electronics manufacturing sector, safeguarding employee safety is of paramount importance. As a result, the Company employs a well-structured approach, aiming for a zero-injuries and fatalities stance. Following the 5S methodology, it proactively prevents and addresses health emergencies. To mitigate potential hazards, workers are equipped with appropriate safety gear in the factories. For instance, in the lighting manufacturing unit, specialized goggles shield employees' eyes from highintensity lights. The Company solidifies its dedication through a distinct occupational health and safety policy.",
    mitigate: "The Company's dedication to preserving workforce health and safety is evident in its record of zero accidents and LTIFR incidents in its operations",
    implication: "Positive implications Protection of health and safety of the workforce will have the positive implication on the Company.",
  },
  {
    materialIssue: "Energy Management and Emission Reduction",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Recognizing that greenhouse gas emissions significantly contribute to escalating climate change risks, the Company has diligently undertaken various measures to curtail its impact on the global greenhouse gas inventory. Prominent among these initiatives is the adoption of renewable energy sources, exemplified by solar panel installation to fulfill energy requirements, alongside the substitution of more environmentally friendly fuels like replacing HSD with PNG. Moreover, the Company consistently gauges its greenhouse gas emissions according to global benchmarks such as ISO 14064 Part 1 and ISO 14064 Part 2. This commitment to sustainability extends to enhancing energy efficiency through similar strategies.",
    mitigate: "Since Company has been undertaking several initiatives to curb greenhouse gas emissions from its operations, it is less likely to pose any risk to the Company.",
    implication: "Positive implications Climate technologies are currently in the nascent stage as a result of which initial investment into these technologies could be a costly affair. However, return on invest in the longer run, will be higher in terms of economical, social and environmental wellbeing.",
  },
  {
    materialIssue: "Waste management",
    riskOpportunity: "Risk",
    rationale: "Risk Perspective: Waste management stands out as a significant concern for the Company. Mishandling waste could result in legal ramifications, fines, and reputational harm. Moreover, a primary waste category stemming from the Company's operations is e-waste, which carries considerable hazards. Inadequate waste management might trigger environmental contamination and jeopardize the well-being of humans and other life forms.",
    mitigate: "The Company adopts a highly proactive stance in its waste management approach. Collaborating with authorized third-party vendors sanctioned by Pollution Control Boards, the Company ensures the secure disposal of waste, including e-waste and plastic waste. This process commences with meticulous segregation at the point of origin – for instance, in the manufacturing facilities, designated bins facilitate segregated waste collection. Subsequently, the segregated waste undergoes recycling or safe disposal by authorized waste collectors. Notably, within the mobile manufacturing division, no waste generated in the production process remains with the Company. All materials, including packaging waste, are returned to the client, making them responsible for proper disposal",
    implication: "Negative implications While engaging a government-authorized recycler might entail certain costs for the Company, these expenses pale in comparison to the substantial positive environmental and social benefits that ensue.",
  },
  {
    materialIssue: "Product Design and Innovation",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: As new technologies emerge and existing ones evolve, the electronics industry, much like other sectors, undergoes substantial transformation. To stay aligned with these evolving needs, proactive measures are vital to consistently innovate product design and manufacturing procedures. In harmony with this principle, Dixon has made substantial investments in enhancing its Research and Development capabilities to elevate the Company's design and operational processes.",
    mitigate: "Company has state-of-theart R&D facilities which have been working tirelessly on innovative technologies for better efficiency and performance of the design and manufacturing processes.",
    implication: "Positive implications It will have positive financial implications as development of new technologies will improve the performance, efficiency and functioning of the existing products. This will increase customer satisfaction and trust which may be reflected in positive revenue growth. However, Company will have to invest certain amount initially to enable the process.",
  },
  {
    materialIssue: "Corporate Governance",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: The Company recognizes that neglecting sound corporate governance can lead to regulatory non-compliance, resulting in fines, penalties, and damage to reputation. Rising legal and financial vulnerabilities: Deficient corporate governance practices can expose the company to legal and financial hazards. To mitigate this, the Company has instituted a robust corporate governance framework, ensuring adherence to all relevant regulatory stipulations. Additionally, the formulation of various policies formalizes the Company's commitment to uphold and foster strong corporate governance practices. The Company maintains an in-house compliance management tool (Lex comply) to stay updated on laws, rules, regulations, and the latest amendments.",
    mitigate: "Company’s policies and good corporate governance practices will help to build a good reputation of the Company in the market. Company’s goal is that it will continue to ensure best-of the corporate governance practices in its business operations.",
    implication: "Positive implications It will result into causing positive financial implication on the Company and build its compliance image in the market and build trust amongst the shareholders.",
  },
  {
    materialIssue: "Community development",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: Company is devoted to build positive and long-term relationships with the communities in which it operates. It is carrying out several CSR projects specifically on promoting education, wellbeing of the senior citizens, eradication of poverty and providing healthcare facilities to the community. Regular engagement of the Company with the community builds strong relationship and creates strong connect with them. Also, it results into creating long-term impact on promoting socio-economic wellbeing of the community.",
    mitigate: "Through its CSR activities, Company has been trying to address some of the major issues being faced by the community people. It results into promoting equitable growth/development of the community closely linked with the Company’s operations.",
    implication: "Positive implications Widespread community projects and activities may have a positive impact on the Company’s reputation and image. Therefore, it will have a positive financial implication on the Company.",
  },
  {
    materialIssue: "Supply chain management",
    riskOpportunity: "Risk",
    rationale: "Risk Perspective: In globalized world, supply chain has become quite complex and extensive. Certain factors are at play for ensuring the robust and disruption-free supply chain like geo-political factors, logistical factors, quality, accessibility and availability of the materials etc. Therefore, it is quite important that effective supply chain management system is established for the sustenance of the business in the emergency situation.",
    mitigate: "The Company recognizes the significance of establishing an efficient supply chain management system, understanding that an uninterrupted and seamless supply chain is crucial for sustaining business operations. To achieve this, the Company has implemented specific measures to avert supply chain disruptions and ensure the efficacy of its supply chain processes. These initiatives encompass prioritizing local suppliers whenever feasible and leveraging SAP technologies for inventory management. Moreover, in the mobile and laptop manufacturing sector, raw materials for the production process are provided by the customer, highlighting their responsibility in the supply chain.",
    implication: "Negative implications Better supply chain management will have positive financial implications on the business.",
  },
  {
    materialIssue: "Climate change strategy",
    riskOpportunity: "Risk",
    rationale: "Risk Perspective: Climate change has emerged as an undeniable reality in today's world. Notably, the repercussions of climate change, such as heatwaves, floods, and landslides, have gained prominence, prompting regulatory measures in response. Just like various other sectors, the electronics industry is vulnerable to both physical and transitional consequences of climate change. To address these challenges proactively, the Company recognizes the need to evaluate factors that could potentially impede its operations.",
    mitigate: "Company believes that first step towards improving lies with the assessment of where we currently stand. Therefore, has been considering conducting the climate risk assessment to clearly understand the impact of climate change on the Company’s operations. It believes that this process will enable in charting out the effective mitigation strategies.",
    implication: "Negative implications Company will incur certain cost initially in assessment and developing mitigation strategies. However, Return on Investment (ROI) in longer run is supposed to be positive.",
  },
  {
    materialIssue: "Human Rights",
    riskOpportunity: "Risk",
    rationale: "Risk Perspective: Human rights represent an intrinsic entitlement for all individuals. The Company acknowledges that safeguarding human rights not only fosters trust but also enhances its reputation among the populace. Conversely, human rights violations could trigger legal or statutory repercussions that might severely mar the Company's image. Given its values, dedication, and compliance with legal mandates, the Company has implemented exemplary measures to ensure the protection of human rights. It has devised and enforced multiple policies, instructing every member associated with the Company to treat others with dignity, respect, and to refrain from any behavior that could lead to harassment or be construed as such. Furthermore, the Company categorically proscribes any engagement with child labor and forced labor within its operations. This prohibition is explicitly embedded within business agreements and contracts, underscoring the Company's commitment to promoting ethical conduct even throughout its supply chain.",
    mitigate: "Company has been continually working to ensure the proper the compliance with the relevant statutory laws and implementation of its policies. It understands that as society is evolving at a rapid pace, it will also have to evolve its practices to be at par with the best practices and create better working environment for the people.",
    implication: "Negative Implications Safeguarding the human rights of the people will only have the positive financial implication on the Company as it will avoid any legal penalties/fines or even the cases against the Company. Better working conditions at the workplace will have the positive impact on the productivity of the employees and workers and also will help build a better reputation of the Company in the market.",
  },
  {
    materialIssue: "Water management",
    riskOpportunity: "Opportunity",
    rationale: "We see it as an opportunity to improve our water efficiency and to minimize our water costs",
    mitigate: "Sewage Treatment Plants (STP) have been set up, RO water which is discharged is re-used in toilets, cleaning utensils etc. Rain water harvesting have also been installed at various locations of the Company. Push Punch water taps have also been installed to ensure minimum wastage.",
    implication: "Positive implications",
  },
  {
    materialIssue: "Customer relationship management",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: Company believes that fostering good relationship with the customer is primordial for business sustenance and growth.",
    mitigate: "-",
    implication: "Positive implications Maintaining better relationship with customers would result into positive financial implication.",
  },
  {
    materialIssue: "Biodiversity management",
    riskOpportunity: "Opportunity",
    rationale: "Opportunity Perspective: Company acknowledges that mismanagement of data can cause significant regulatory implications as well as reputational damage to the Company. To enable the same, Company has been taking initiatives to improve the information technology system of the Company. For example, Company has been getting their information technology system certified with ISO 27001.",
    mitigate: "Company has formulated and implemented data privacy policy and also has been strengthening information security system. The Company has been on a regular basis monitoring their softwares and framing stringent policies for cyber security. The Company has implemented access control and IPS/IDS in firewall which reduced the risk of unwanted access and hacking",
    implication: "This will have positive financial implications on the Company as it will prevent data breaches and other cybersecurity related risks.",
  },
];
