import { H3Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "", rowSpan: 2 },
  { label: "Male", align: "center", colSpan: 2 },
  { label: "Female", align: "center", colSpan: 2 },
];

const subHeaderData2 = [
  { label: "Number", align: "center" },
  { label: "Median remuneration/ salary/ wages of respective category", align: "center" },
  { label: "Number", align: "center" },
  { label: "Median remuneration/ salary/ wages of respective category", align: "center" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H3 = () => {
  return (

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData2.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} colSpan={colSpan} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
            
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Board of Directors (BoD)",
                data: [
                  H3Data.maleNumberBoardOfDirector,
                  H3Data.maleMedianSalaryBoardOfDirector,
                  H3Data.femaleNumberBoardOfDirector,
                  H3Data.femaleMedianSalaryBoardOfDirector,
                ],
              },
              {
                id: 1,
                label: "Key Managerial Personnel",
                data: [
                  H3Data.maleNumberKeyManagerialPerson,
                  H3Data.maleMedianSalaryKeyManagerialPerson,
                  H3Data.femaleNumberKeyManagerialPerson,
                  H3Data.femaleMedianSalaryKeyManagerialPerson,
                ],
              },
              {
                id: 1,
                label: "Employees other than BoD and KMP",
                data: [
                  H3Data.maleNumberEmployee,
                  H3Data.maleMedianSalaryEmployee,
                  H3Data.femaleNumberEmployee,
                  H3Data.femaleMedianSalaryEmployee,
                ],
              },
              {
                id: 1,
                label: "Workers (Permanent)",
                data: [
                  H3Data.maleNumberWorker,
                  H3Data.maleMedianSalaryWorker,
                  H3Data.femaleNumberWorker,
                  H3Data.femaleMedianSalaryWorker,
                ],
              },
            ])}
            
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default H3;
