import { H2Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "Particulars", rowSpan: 3 },
  { label: "FY 2022-23", align: "center", colSpan: 5 },
  { label: "FY 2021-22", align: "center", colSpan: 5 },
];

const subHeaderData2 = [
  { label: "Total (A)", align: "center", rowSpan: 2 },
  { label: "Equal to min wage", colSpan: "2", align: "center" },
  { label: "More than min wage", colSpan: "2", align: "center" },
  { label: "Total (D)", align: "center", rowSpan: 2  },
  { label: "Equal to min wage", colSpan: "2", align: "center" },
  { label: "More than min wage", colSpan: "2", align: "center" },
];

const subSubHeaderData2 = [
  { label: "No. (B)", align: "center" },
  { label: "% (B/A)", align: "center" },
  { label: "No. (C)", align: "center" },
  { label: "% (C/A)", align: "center" },
  { label: "No. (E)", align: "center" },
  { label: "% (E/D)", align: "center" },
  { label: "No. (F)", align: "center" },
  { label: "% (F/D)", align: "center" },
];

const renderTableRow = (colSpan, label) => (
  <TableRow>
    <TableCell component="th" scope="row" align="center" colSpan={colSpan}>
      <strong>{label}</strong>
    </TableCell>
  </TableRow>
);

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H2 = () => {
  return (

      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData2.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} colSpan={colSpan} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subSubHeaderData2.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderTableRow(18, "Permanent Employees")}
            {renderDataRows([
              {
                id: 1,
                label: "Male",
                data: [
                  H2Data.totalPermanentMaleEmployee23,
                  H2Data.equalToMinimumPermanentMaleEmployeeNumber23,
                  H2Data.equalToMinimumPermanentMaleEmployeePercentage23,
                  H2Data.moreThanMinimumPermanentMaleEmployeeNumber23,
                  H2Data.moreThanMinimumPermanentMaleEmployeePercentage23,
                  H2Data.totalPermanentMaleEmployee22,
                  H2Data.equalToMinimumPermanentMaleEmployeeNumber22,
                  H2Data.equalToMinimumPermanentMaleEmployeePercentage22,
                  H2Data.moreThanMinimumPermanentMaleEmployeeNumber22,
                  H2Data.moreThanMinimumPermanentMaleEmployeePercentage22,
                ],
              },
              {
                id: 2,
                label: "Female",
                data: [
                  H2Data.totalPermanentFemaleEmployee23,
                  H2Data.equalToMinimumPermanentFemaleEmployeeNumber23,
                  H2Data.equalToMinimumPermanentFemaleEmployeePercentage23,
                  H2Data.moreThanMinimumPermanentFemaleEmployeeNumber23,
                  H2Data.moreThanMinimumPermanentFemaleEmployeePercentage23,
                  H2Data.totalPermanentFemaleEmployee22,
                  H2Data.equalToMinimumPermanentFemaleEmployeeNumber22,
                  H2Data.equalToMinimumPermanentFemaleEmployeePercentage22,
                  H2Data.moreThanMinimumPermanentFemaleEmployeeNumber22,
                  H2Data.moreThanMinimumPermanentFemaleEmployeePercentage22,
                ],
              },
            ])}
            {renderTableRow(18, "Other than Permanent Employees")}
            {renderDataRows([
              {
                id: 1,
                label: "Male",
                data: [
                  H2Data.totalOtherThanPermanentMaleEmployee23,
                  H2Data.equalToMinimumOtherThanPermanentMaleEmployeeNumber23,
                  H2Data.equalToMinimumOtherThanPermanentMaleEmployeePercentage23,
                  H2Data.moreThanMinimumOtherThanPermanentMaleEmployeeNumber23,
                  H2Data.moreThanMinimumOtherThanPermanentMaleEmployeePercentage23,
                  H2Data.totalOtherThanPermanentMaleEmployee22,
                  H2Data.equalToMinimumOtherThanPermanentMaleEmployeeNumber22,
                  H2Data.equalToMinimumOtherThanPermanentMaleEmployeePercentage22,
                  H2Data.moreThanMinimumOtherThanPermanentMaleEmployeeNumber22,
                  H2Data.moreThanMinimumOtherThanPermanentMaleEmployeePercentage22,
                ],
              },
              {
                id: 2,
                label: "Female",
                data: [
                  H2Data.totalOtherThanPermanentFemaleEmployee23,
                  H2Data.equalToMinimumOtherThanPermanentFemaleEmployeeNumber23,
                  H2Data.equalToMinimumOtherThanPermanentFemaleEmployeePercentage23,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleEmployeeNumber23,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleEmployeePercentage23,
                  H2Data.totalOtherThanPermanentFemaleEmployee22,
                  H2Data.equalToMinimumOtherThanPermanentFemaleEmployeeNumber22,
                  H2Data.equalToMinimumOtherThanPermanentFemaleEmployeePercentage22,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleEmployeeNumber22,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleEmployeePercentage22,
                ],
              },
            ])}
            {renderTableRow(18, "Permanent Workes")}
            {renderDataRows([
              {
                id: 1,
                label: "Male",
                data: [
                  H2Data.totalPermanentMaleWorker23,
                  H2Data.equalToMinimumPermanentMaleWorkerNumber23,
                  H2Data.equalToMinimumPermanentMaleWorkerPercentage23,
                  H2Data.moreThanMinimumPermanentMaleWorkerNumber23,
                  H2Data.moreThanMinimumPermanentMaleWorkerPercentage23,
                  H2Data.totalPermanentMaleWorker22,
                  H2Data.equalToMinimumPermanentMaleWorkerNumber22,
                  H2Data.equalToMinimumPermanentMaleWorkerPercentage22,
                  H2Data.moreThanMinimumPermanentMaleWorkerNumber22,
                  H2Data.moreThanMinimumPermanentMaleWorkerPercentage22,
                ],
              },
              {
                id: 2,
                label: "Female",
                data: [
                  H2Data.totalPermanentFemaleWorker23,
                  H2Data.equalToMinimumPermanentFemaleWorkerNumber23,
                  H2Data.equalToMinimumPermanentFemaleWorkerPercentage23,
                  H2Data.moreThanMinimumPermanentFemaleWorkerNumber23,
                  H2Data.moreThanMinimumPermanentFemaleWorkerPercentage23,
                  H2Data.totalPermanentFemaleWorker22,
                  H2Data.equalToMinimumPermanentFemaleWorkerNumber22,
                  H2Data.equalToMinimumPermanentFemaleWorkerPercentage22,
                  H2Data.moreThanMinimumPermanentFemaleWorkerNumber22,
                  H2Data.moreThanMinimumPermanentFemaleWorkerPercentage22,
                ],
              },
            ])}
            {renderTableRow(18, "Other than Permanent Workes")}
            {renderDataRows([
              {
                id: 1,
                label: "Male",
                data: [
                  H2Data.totalOtherThanPermanentMaleWorker23,
                  H2Data.equalToMinimumOtherThanPermanentMaleWorkerNumber23,
                  H2Data.equalToMinimumOtherThanPermanentMaleWorkerPercentage23,
                  H2Data.moreThanMinimumOtherThanPermanentMaleWorkerNumber23,
                  H2Data.moreThanMinimumOtherThanPermanentMaleWorkerPercentage23,
                  H2Data.totalOtherThanPermanentMaleWorker22,
                  H2Data.equalToMinimumOtherThanPermanentMaleWorkerNumber22,
                  H2Data.equalToMinimumOtherThanPermanentMaleWorkerPercentage22,
                  H2Data.moreThanMinimumOtherThanPermanentMaleWorkerNumber22,
                  H2Data.moreThanMinimumOtherThanPermanentMaleWorkerPercentage22,
                ],
              },
              {
                id: 2,
                label: "Female",
                data: [
                  H2Data.totalOtherThanPermanentFemaleWorker23,
                  H2Data.equalToMinimumOtherThanPermanentFemaleWorkerNumber23,
                  H2Data.equalToMinimumOtherThanPermanentFemaleWorkerPercentage23,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleWorkerNumber23,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleWorkerPercentage23,
                  H2Data.totalOtherThanPermanentFemaleWorker22,
                  H2Data.equalToMinimumOtherThanPermanentFemaleWorkerNumber22,
                  H2Data.equalToMinimumOtherThanPermanentFemaleWorkerPercentage22,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleWorkerNumber22,
                  H2Data.moreThanMinimumOtherThanPermanentFemaleWorkerPercentage22,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default H2;
