import {
  H1Data,
  H2Data,
  H3Data,
  H4Data,
  H5Data,
  H6Data,
  H7Data,
  H8Data,
  H9Data,
  H10Data,
  H11Data,
  H12Data,
  H13Data,
} from "./data.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData24 = [
  { label: "S.No", align: "center", rowSpan: 2, colWidth: "5%" },
  { label: "Particulars held", align: "center", rowSpan: 2, colWidth: "45%" },
  { label: "Response", align: "center", rowSpan: 2, colWidth: "50%"},
];

const data = [
      {
        id: 1,
        key: "Corporate Identity Number (CIN) of the listed entity",
        value: H1Data.cin,
      },
      {
        id: 2,
        key: "Name of the listed entity	",
        value: H2Data.name,
      },
      {
        id: 3,
        key: "Year of incorporation	",
        value: H3Data.incorporation,
      },
      {
        id: 4,
        key: "Registered office address	",
        value: H4Data.registeredOffice,
      },
      {
        id: 5,
        key: "Corporate address",
        value: H5Data.address,
      },
      {
        id: 6,
        key: "E-mail",
        value: H6Data.email,
      },
      {
        id: 7,
        key: "Telephone",
        value: H7Data.telephone,
      },
      {
        id: 8,
        key: "Website",
        value: H8Data.website,
      },
      {
        id: 9,
        key: "Financial year for which reporting is being done",
        value: H9Data.financialYear,
      },
      {
        id: 10,
        key: "Name of the Stock Exchange(s) where shares are listed	",
        value: H10Data.exchange,
      },
      {
        id: 11,
        key: "Paid-up Capital	",
        value: H11Data.capital,
      },
      {
        id: 12,
        key: "Name and contact details (telephone, email address) of the person who may be contacted in case of any queries on the BRSR report",
        value: [H12Data.name, H12Data.email, H12Data.mobile],
      },
      {
        id: 13,
        key: "Reporting boundary-Are the disclosures under this report made on a standalone basis (i.e., only for the entity) or on a consolidated basis (i.e., for the entity and all the entities that form part of its consolidated financial statements taken together)?	",
        value: H13Data.boundary,
      },
    ];

const H1 = () => {
  return (
    <div>
      
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData24.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  style={{width: colWidth}}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {
            data.map((data, index) => (
              <TableRow key={++index}>
                <TableCell key={index} align="center">
                  {data.id}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.key}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.id == "12" ? data.value.map(e => <p>{e}</p>) : data.value}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
      

    </div>
  );
};

export default H1;
