import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H2Data, H3Data } from "./data.js";
import { Divider } from "antd";


const headerData2 = [
  { label: "", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "", align: "center", rowSpan: 2, colWidth: "20%" },
  { label: "FY 2022-23", align: "center", colSpan: 2, colWidth: "25%" },
  { label: "Remarks", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "FY 2021-22", align: "center", colSpan: 2, colWidth: "25%" },
  { label: "Remarks", align: "center", rowSpan: 2, colWidth: "10%" },
];

const subHeaderData2 = [
  { label: "Received during the year", align: "center" },
  { label: "Pending resolution at the end of year", align: "center" },
  { label: "Received during the year", align: "center" },
  { label: "Pending resolution at the end of year", align: "center" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H3 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData2.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Data privacy",
                data: [
                  H3Data.privacyFile_23,
                  H3Data.privacyPending_23,
                  H3Data.privacyremarks_23,
                  H3Data.privacyFile_22,
                  H3Data.privacyPending_22,
                  H3Data.privacyremarks_22,
                ],
              },
              {
                id: 2,
                label: "Advertising",
                data: [
                  H3Data.advertisingFile_23,
                  H3Data.advertisingPending_23,
                  H3Data.advertisingRemarks_23,
                  H3Data.advertisingFile_22,
                  H3Data.advertisingPending_22,
                  H3Data.advertisingRemarks_22,
                ],
              },
              {
                id: 3,
                label: "Cyber-Security",
                data: [
                  H3Data.cyberSecurityFile_23,
                  H3Data.cyberSecurityPending_23,
                  H3Data.cyberSecurityRemarks_23,
                  H3Data.cyberSecurityFile_22,
                  H3Data.cyberSecurityPending_22,
                  H3Data.cyberSecurityRemarks_22,
                ],
              },
              {
                id: 4,
                label: "Delivery of essential services",
                data: [
                  H3Data.deliveryFile_23,
                  H3Data.deliveryPending_23,
                  H3Data.deliveryRemarks_23,
                  H3Data.deliveryFile_22,
                  H3Data.deliveryPending_22,
                  H3Data.deliveryRemarks_22,
                ],
              },
              {
                id: 5,
                label: "Restrictive trade practices",
                data: [
                  H3Data.restrcitiveFile_23,
                  H3Data.restrcitivePending_23,
                  H3Data.restrcitiveRemarks_23,
                  H3Data.restrcitiveFile_22,
                  H3Data.restrcitivePending_22,
                  H3Data.restrcitiveRemarks_22,
                ],
              },
              {
                id: 6,
                label: "Unfair trade practices",
                data: [
                  H3Data.unfairTradeFile_23,
                  H3Data.unfairTradePending_23,
                  H3Data.unfairRemarks_23,
                  H3Data.unfairTradeFile_22,
                  H3Data.unfairTradePending_22,
                  H3Data.unfairRemarks_22,
                ],
              },
              {
                id: 7,
                label: "Other",
                data: [
                  H3Data.otherFile_23,
                  H3Data.otherPending_23,
                  H3Data.otherRemarks_23,
                  H3Data.otherFile_22,
                  H3Data.otherPending_22,
                  H3Data.otherRemarks_22,
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider/>
    </div>
  );
};

export default H3;
