import React, { useState } from 'react';
import { Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { P6Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P6/H1';
import H2 from './P6/H2';
import H3 from './P6/H3';
import H4 from './P6/H4';
import H5 from './P6/H5';
import H6 from './P6/H6';
import H7 from './P6/H7';
import H8 from './P6/H8';
import H9 from './P6/H9';
import H10 from './P6/H10';
import H11 from './P6/H11';
import H12 from './P6/H12';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import FactoryIcon from "@mui/icons-material/Factory";
import GroupsOutlinedIcon from '@mui/icons-material/Groups2Outlined';
import FemaleOutlinedIcon from '@mui/icons-material/FemaleOutlined';
import Face3OutlinedIcon from '@mui/icons-material/Face3Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';

const { Panel } = Collapse;

const Principle6 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P6Heading[0].description:
        return <H1 />;
      case P6Heading[1].description:
        return <H2 />;
      case P6Heading[2].description:
        return <H3 />;
      case P6Heading[3].description:
        return <H4 />;
      case P6Heading[4].description:
        return <H5 />;
      case P6Heading[5].description:
        return <H6 />;
      case P6Heading[6].description:
        return <H7 />;
      case P6Heading[7].description:
        return <H8 />;
      case P6Heading[8].description:
        return <H9 />;
      case P6Heading[9].description:
        return <H10 />;
      case P6Heading[10].description:
        return <H11 />;
      case P6Heading[11].description:
        return <H12 />;
      default:
        return <H1 />;
    }

  }

  const highlight = [
    { name: "Energy intensity per rupee of turnovers", icon: <BoltOutlinedIcon style={{ fontSize: "42px" }} />, value: "0.015" },
    { name: "GHG emission intensity per rupee of turnover", icon: <EqualizerOutlinedIcon style={{ fontSize: "42px" }} />, value: "2.6" },
    { name: "Fines and penalties for environmental violations", icon: <CurrencyRupeeOutlinedIcon style={{ fontSize: "42px" }} />, value: "Nil" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="" onClose={onClose} open={open}>
        <p>Dixon acknowledges the imperative of conducting business with responsibility and sustainability at its core. As a committed
entity, Dixon Technologies places significant emphasis on diminishing its environmental footprint. The company espouses the
adoption of top-tier environmental practices and has effectively implemented policies and procedures that drive the ongoing
enhancement of its practices, systems, and operations.</p>
<p>
A pivotal aspect of this initiative involves the gradual replacement of utilities in the Company's multiple factories with more
energy-efficient models and technologies. The Company proactively addresses mounting challenges, such as climate change
and global warming, by investing in solutions that not only address these issues but also transform them into opportunities.
Addressing global environmental challenges is treated both as a commercial imperative and a moral responsibility toward the
environment
</p>
<p>
Efforts persist in amplifying the utilization of renewable energy, clean fuels, eco-friendly materials, energy-efficient products,
conservation strategies, and the reduction of reliance on finite resources. The Business Responsibility Policy of the Company
greatly facilitates this pursuit. Furthermore, the Company's facilities uphold some of the most stringent international quality
benchmarks, including ISO 14001.
</p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[7].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[7].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[1]}
            ghost
          >
            {
              P6Heading.map((data, index) => {
                if(index<12) {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                      <Divider/>
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
    </div>
  );
};
export default Principle6;