import { H1Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData2 = [
  { label: "", align: "center", rowSpan: 2, colWidth: "40%" },
  { label: "NGRBC Principle", rowSpan: 2, colWidth: "10%" },
  { label: "Name of the regulatory/ Enforcement agencies/ judicial institutions", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "Amount (In Rs)", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "Brief of the Case", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "Has an appeal been preferred? (Yes/No)", align: "center", rowSpan: 2, colWidth: "10%" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell>{label}</TableCell>
      {
        id == 1 ?
          <TableCell rowSpan={4} colSpan={5} align="center">
          {"No penalties have been levied on the Company"}
        </TableCell> : ""
      }
    </TableRow>
  ));

const H1 = () => {
  return (
    <div className="text-padding-left ">
      <p>
        <strong>NIL</strong>
      </p>
      <p>
        Dixon Technologies is resolute in adhering to the rule of law within its
        operational jurisdiction and fully complies with all relevant
        regulations. This commitment is substantiated by the absence of any
        fines or penalties, monetary or otherwise, imposed on the Company's
        directors or key management personnel by regulators, law enforcement
        agencies, or judicial institutions during the reviewed financial year.
        This serves as evidence of the Company's dedication to conducting its
        operations in accordance with applicable regulations and its continuous
        efforts to enhance overall performance, even encompassing sustainability
        aspects whenever feasible
      </p>
      <br/>
      <h4>Monetary</h4>
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Penalty/Fine",
                data: [],
              },
              {
                id: 2,
                label: "Settlement",
                data: [],
              },
              {
                id: 3,
                label: "Compounding Fee",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

      <br/>
      <h4>Non Monetary</h4>
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Imprisonment",
                data: [],
              },
              {
                id: 2,
                label: "Punishment",
                data: [],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  );
};

export default H1;
