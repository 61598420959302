import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AllPrinciple from "./component/AllPrinciple";
import Principle1 from "./component/Principle1";
import Principle2 from "./component/Principle2";
import Principle3 from "./component/Principle3";
import Principle4 from "./component/Principle4";
import Principle5 from "./component/Principle5";
import Principle6 from "./component/Principle6";
import Principle7 from "./component/Principle7";
import Principle8 from "./component/Principle8";
import Principle9 from "./component/Principle9";
import SectionA from "./component/SectionA";
import SectionB from "./component/SectionB";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { appTheme } from "./config";

const drawerWidth = 240;
const navItems = [];
  

const router = createBrowserRouter([
  {
    path: "/",
    element: <AllPrinciple />,
  },
  {
    path: "/principle-1",
    element: <Principle1 />,
  },
  {
    path: "/principle-2",
    element: <Principle2 />,
  },
  {
    path: "/principle-3",
    element: <Principle3 />,
  },
  {
    path: "/principle-4",
    element: <Principle4 />,
  },
  {
    path: "/principle-5",
    element: <Principle5 />,
  },
  {
    path: "/principle-6",
    element: <Principle6 />,
  },
  {
    path: "/principle-7",
    element: <Principle7 />,
  },
  {
    path: "/principle-8",
    element: <Principle8 />,
  },
  {
    path: "/principle-9",
    element: <Principle9 />,
  },
  {
    path: "/section-a",
    element: <SectionA />,
  },
  {
    path: "/section-b",
    element: <SectionB />,
  },

]);

function App(props) {

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Dixon
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;


  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" sx={{background: appTheme.background.light, zIndex: "1"}}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: appTheme.fontColor.dark }}
            onClick={() => {}}
          >
            Dixon
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: appTheme.fontColor.dark }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <RouterProvider router={router} />
      </Box>
    </Box>
      
  );
}
  
export default App;