import { H21Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData21 = [
  { label: "Sr No.", align: "center", rowSpan: 2, colWidth: "6%" },
  { label: "Name(A)", align: "center", rowSpan: 6, colWidth: "30%" },
  { label: "Type of holding/subsidiary/associate/joint venture", align: "center", rowSpan: 2, colWidth: "15%"},
  { label: "% of shares held by the listed entity", align: "center", rowSpan: 2, colWidth: "15%" },
  { label: "Does the entity indicated at column A, participate in the business responsibility initiatives of the listed entit", align: "center", rowSpan: 2, colWidth: "40%" },
];

const H5 = () => {
  return (
    <div>
      <p>
        <strong>
          V-21. (a) Names of Holding, Subsidiary, Associate Companies, and Joint Ventures
        </strong>
      </p>

      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData21.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  style={{width: colWidth}}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {
            H21Data.map((data, index) => (
              <TableRow key={++index}>
                <TableCell component="th" scope="row" align="center">
                  {++index}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.company}
                </TableCell>
                <TableCell key={index} align="center">
                  {data.typeOfVenture}
                </TableCell>
                <TableCell key={index} align="center">
                  {data.shares}
                </TableCell>
                <TableCell key={index} align="center">
                  {data.isparticipate}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
      

    </div>
  );
};

export default H5;
