import { Alert, Card, Col, Collapse, Divider, Row, Drawer, Button } from 'antd';
import { useState } from 'react';
import { P9Heading } from '../constants/Heading';
import { BRSRSchema } from '../constants/Principle';
import H1 from './P9/H1';
import H2 from './P9/H2';
import H3 from './P9/H3';
import H4 from './P9/H4';
import H5 from './P9/H5';
import H6 from './P9/H6';
import H7 from './P9/H7';
import H8 from './P9/H8';
import H9 from './P9/H9';
import H10 from './P9/H10';
import H11 from './P9/H11';
import { appTheme } from '../config';
import { generalDislosureComponent, leadershipMessageComponent } from '../utility/Common';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

const { Panel } = Collapse;

const Principle9 = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const getHeadingData = (description) => {
    switch (description) {
      case P9Heading[0].description:
        return <H1 />;
      case P9Heading[1].description:
        return <H2 />;
      case P9Heading[2].description:
        return <H3 />;
      case P9Heading[3].description:
        return <H4 />;
      case P9Heading[4].description:
        return <H5 />;
      case P9Heading[5].description:
        return <H6 />;
      case P9Heading[6].description:
        return <H7 />;
      case P9Heading[7].description:
        return <H8 />;
      case P9Heading[8].description:
        return <H9 />;
      case P9Heading[9].description:
        return <H10 />;
      case P9Heading[10].description:
        return <H11 />;
      default:
        return <H1 />;
    }
  }

  const highlight = [
    { name: "Customer complaints on critical issues", icon: <BugReportOutlinedIcon style={{ fontSize: "42px" }} />, value: "Nil" },
    { name: "Available on company website", icon: <PreviewOutlinedIcon style={{ fontSize: "42px" }} />, value: "Data Privacy Policy" },
  ];

  const commentryComponent = () => {
    return (
      <>
      <Drawer closable={false} size="large" title="Dixon Technologies firmly believes in the customers first policy. Our customers are invaluable assets who are vital to our long-term success." onClose={onClose} open={open}>
        <p>They rely on our infrastructure and experience to provide high-quality products to end users. As a result, we strive to provide high-quality products that fulfil a wide range of needs. We aim to engage with our customers from time to time and add value through our world class products in a responsible manner. </p>
      </Drawer>
      </>
    )
  }

  return (
    <div>
      <Row style={{paddingBottom: "10px"}}>
        <Col offset={1} span={21}>
          <Card style={{ width: "100%", background: appTheme.colors.secondry }}>
            <h1 style={{color: "white", paddingTop: "12px", alignItems: "center"}}>{BRSRSchema[10].title}</h1>
            <p style={{color: "white"}}>{BRSRSchema[10].description}</p>
            <Button type="primary" style={{background: appTheme.colors.primary, marginTop:"8px", position: "absolute"}} onClick={showDrawer}>
              Read Commentry..
            </Button>
          </Card>
        </Col>
      </Row>
      {leadershipMessageComponent()}
      {commentryComponent()}
      {generalDislosureComponent(highlight)}
      <Row >
        <Col offset={1} span={21}>
          <h4>Essential Indicators</h4>
          <Collapse
            defaultActiveKey={[1]}
            ghost
          >
            {
              P9Heading.map((data, index) => {
                if(index<6) {
                return (
                  <>
                    <Panel header={(++index) + ". " + data.description} key={index}>
                      {getHeadingData(data.description)}
                    </Panel>
                  </>
                )
              }})
            }

          </Collapse>
        </Col>
      </Row>
      <br/>
      {/* <Row >
        <Col offset={1} span={21}>
          <h4>Leadership Indicators</h4>
          <Collapse
            defaultActiveKey={["6"]}
            ghost
          >
            {
              P9Heading.map((data, index) => {
                if(index>5) {
                return (
                  <>
                    <Panel header={(index+1) + ". " + data.description} key={index}>
                        {getHeadingData(data.definationId)}
                    </Panel>
                  </>
                )
              } })
            }

          </Collapse>
        </Col>
      </Row> */}
    </div>
  );
};
export default Principle9;