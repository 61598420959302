import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H4Data } from "./data.js";
import { Divider } from "antd";


const headerData4 = [
  { label: "", align: "center", rowSpan: 2, colWidth: "10%" },
  { label: "Calegory", align: "left", rowSpan: 2, colWidth: "20%" },
  { label: "Number", align: "center", rowSpan: 2, colWidth: "25%" },
  { label: "Reasons for recall", align: "center", rowSpan: 2, colWidth: "25%" },
];

const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H4 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData4.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Voluntary recalls",
                data: [
                  H4Data.voluntaryNumber,
                  H4Data.voluntaryReason
                ],
              },
              {
                id: 2,
                label: "Forced recalls",
                data: [
                  H4Data.forcedNumber,
                  H4Data.forcedReason
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      <Divider/>
    </div>
  );
};

export default H4;
