import {
  H3Data,
} from "./data.js";

const H3 = () => {
  return (
    <div className="text-padding-left">
      <p>{H3Data.mechanism}</p>  
    </div>
  );
};

export default H3;
