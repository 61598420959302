import {
  H1Data,
} from "./data.js";

const H1 = () => {
  return (
    <div className="text-padding-left">
      <p>{H1Data.number}</p>  
    </div>
  );
};

export default H1;
