import { H7Data } from "./data.js";

const H7 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H7Data.isProjectGhg}</p>
    </div>
  );
};

export default H7;
