import { H5Data } from "./data.js";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData1 = [
  { label: "Subject of Review", align: "center", rowSpan: 2 },
  { label: "Indicate whether review was undertaken by Director/ Committee of the Board/ Any other Committee", align: "center", colSpan: 9 },
];

const subHeaderData1 = [
  { label: "P1", align: "center" },
  { label: "P2", align: "center" },
  { label: "P3", align: "center" },
  { label: "P4", align: "center" },
  { label: "P5", align: "center" },
  { label: "P6", align: "center" },
  { label: "P7", align: "center" },
  { label: "P8", align: "center" },
  { label: "P9", align: "center" },
];


const renderDataRows = (data) =>
  data.map(({ id, label, data, fontSize, align }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell
          style={{ fontSize: fontSize }}
          key={index}
          align={align ? align : "center"}
        >
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const renderSingleResponse = ({ id, key, value }) => (
  <TableRow key={id}>
    <TableCell component="th" scope="row" align="center">
      {id}
    </TableCell>
    <TableCell>{key}</TableCell>
    <TableCell key={id} colSpan={9} align="left">
      <a href={value}>{value}</a>
    </TableCell>
  </TableRow>
);

const H5 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData1.map(
                ({ label, align, rowSpan, colSpan, colWidth }, index) => (
                  <TableCell
                    key={index}
                    align={align}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                    style={{ width: colWidth }}
                  >
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {subHeaderData1.map(
                ({ label, align, rowSpan, colSpan }, index) => (
                  <TableCell key={index} align={align} rowSpan={rowSpan}>
                    <strong>{label}</strong>
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
          <TableRow>
            <TableCell component="th" rowSpan={2} scope="row" align="center">
              Performance against above policies and follow up action and frequency of review for performance against above policies and follow up action 
            </TableCell>
            <TableCell>{H5Data.isPerformancep1}</TableCell>
            <TableCell>{H5Data.isPerformancep2}</TableCell>
            <TableCell>{H5Data.isPerformancep3}</TableCell>
            <TableCell>{H5Data.isPerformancep4}</TableCell>
            <TableCell>{H5Data.isPerformancep5}</TableCell>
            <TableCell>{H5Data.isPerformancep6}</TableCell>
            <TableCell>{H5Data.isPerformancep7}</TableCell>
            <TableCell>{H5Data.isPerformancep8}</TableCell>
            <TableCell>{H5Data.isPerformancep9}</TableCell>           
          </TableRow>
          <TableRow>
            <TableCell component="th" colSpan={9} scope="row" align="left">{H5Data.performanceDescription}</TableCell>                       
          </TableRow>
          <TableRow>
            <TableCell component="th" rowSpan={2} scope="row" align="center">
              Compliance with statutory requirements of relevance to the principles, and rectification of any noncompliances and frequency of review
            </TableCell>
            <TableCell>{H5Data.isCompliancep1}</TableCell>
            <TableCell>{H5Data.isCompliancep2}</TableCell>
            <TableCell>{H5Data.isCompliancep3}</TableCell>
            <TableCell>{H5Data.isCompliancep4}</TableCell>
            <TableCell>{H5Data.isCompliancep5}</TableCell>
            <TableCell>{H5Data.isCompliancep6}</TableCell>
            <TableCell>{H5Data.isCompliancep7}</TableCell>
            <TableCell>{H5Data.isCompliancep8}</TableCell>
            <TableCell>{H5Data.isCompliancep9}</TableCell>           
          </TableRow>
          <TableRow>
            <TableCell component="th" colSpan={9} scope="row" align="left">{H5Data.complianceDescription}</TableCell>                       
          </TableRow>
            
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H5;
