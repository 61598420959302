import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";
import { H5Data } from "./data.js";

const headerData5 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "Unit", align: "center"},
  { label: "FY 2022-23", align: "center"},
  { label: "FY 2021-22", align: "center"},
];

const renderDataRows = (data) =>
  data.map(({ id, label, data, bold }) => (
    <TableRow key={id}>
      <TableCell style={{fontWeight: bold ? "bold" : "normal"}}>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell style={{fontWeight: bold ? "bold" : "normal"}} key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H5 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H5Data.variable}</p>
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData5.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  colWidth={colWidth}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "NOx",
                data: [
                  "g/kwh",
                  H5Data.nox23,
                  H5Data.nox22
                ],
              },
              {
                id: 2,
                label: "SOx",
                data: [
                  "g/kwh",
                  H5Data.sox23,
                  H5Data.sox22
                ],
              },
              {
                id: 3,
                label: "Particulate Matter (PM)",
                data: [
                  "g/kwh",
                  H5Data.pm23,
                  H5Data.pm22
                ],
              },
              {
                id: 4,
                label: "Persistent Organic Pollutants (POP)",
                data: [
                  "-",
                  H5Data.pop23,
                  H5Data.pop22
                ],
              },
              {
                id: 5,
                label: "Volatile organic Compounds (VOC)",
                data: [
                  "-",
                  H5Data.voc23,
                  H5Data.voc22
                ],
              },
              {
                id: 6,
                label: "Hazardous air pollutants (HAP)",
                data: [
                  "-",
                  H5Data.hap23,
                  H5Data.hap22
                ],
              },
              {
                id: 7,
                label: "Others- please specify",
                data: [
                  "-",
                  H5Data.others23,
                  H5Data.others22
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
      
      <br></br>
      <p>Note: Indicate if any independent assessment/ evaluation/assurance has been carried out by an external agency? (Y/N) If yes, name of the external agency- No</p>
    </div>
  );
};

export default H5;
