import { H2Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";


const headerData2 = [
  { label: "Stakeholder Group", align: "center", rowSpan: 2, colWidth: "8%" },
  { label: "Whether identified as Vulnerable & Marginalized Group (Yes/No)", align: "center", rowSpan: 2, colWidth: "4%" },
  { label: "Channels of communication (Email, SMS, Newspaper, Pamphlets, Advertisement, Community Meetings, Notice Board, Website), Other", align: "center", rowSpan: 2, colWidth: "30%"},
  { label: "Frequency of engagement (Annually/ Half yearly/ Quarterly / others – please specify)", align: "center", rowSpan: 2, colWidth: "25%" },
  { label: "Purpose and scope of engagement including key topics and concerns raised during such engagement", align: "center", rowSpan: 2, colWidth: "25%" },
];

const H2 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData2.map(({ label, align, rowSpan, colSpan, colWidth }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                  style={{width: colWidth}}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {
            H2Data.map((data, index) => (
              <TableRow key={++index}>
                <TableCell key={index} align="left">
                  {data.stakeholderGroup}
                </TableCell>
                <TableCell key={index} align="left">
                  {data.isVulnerableMarginalized}
                </TableCell>
                <TableCell  style={{fontSize: "12px"}} key={index} align="left">
                  {data.channelOfCommunication}
                </TableCell>
                <TableCell style={{fontSize: "12px"}} key={index} align="left">
                  {data.frequency}
                </TableCell>
                <TableCell style={{fontSize: "12px"}} key={index} align="left">
                  {data.purpose}
                </TableCell>
              </TableRow>
            ))
          }
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H2;
