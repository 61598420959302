export const appTheme = {
    colors : {
        primary: '#da2128',
        secondry: '#231f5a'
    },
    background: {
        light: '#ffffff'
    },
    fontColor : {
        light: '#ffffff',
        dark: '#000000',

    }
}