export const H1Data = {
  isPolicyP1: "Y",
  isPolicyP2: "Y",
  isPolicyP3: "Y",
  isPolicyP4: "Y",
  isPolicyP5: "Y",
  isPolicyP6: "Y",
  isPolicyP7: "Y",
  isPolicyP8: "Y",
  isPolicyP9: "Y",
  isPolicyApprovedP1: "Y",
  isPolicyApprovedP2: "Y",
  isPolicyApprovedP3: "Y",
  isPolicyApprovedP4: "Y",
  isPolicyApprovedP5: "Y",
  isPolicyApprovedP6: "Y",
  isPolicyApprovedP7: "Y",
  isPolicyApprovedP8: "Y",
  isPolicyApprovedP9: "Y",
  linkOfPolicies: "https://dixoninfo.com/codes-policies.php",
  translatedPolicyP1: "Y",
  translatedPolicyP2: "Y",
  translatedPolicyP3: "Y",
  translatedPolicyP4: "Y",
  translatedPolicyP5: "Y",
  translatedPolicyP6: "Y",
  translatedPolicyP7: "Y",
  translatedPolicyP8: "Y",
  translatedPolicyP9: "Y",
  isValueChainPartners: "Yes, the Anti- Bribery and Anti- Corruption policy is extended to the value chain partners as well. The scope of the policy includes employees, directors, agents, consultants, contractors, customers and to all such people and institutions to which the Dixon Group (i.e. Dixon and it’s subsidiaries and Joint venture Companies) is associated with, irrespective of the location",
  codeCertificationsRemark: "Company has got multiple nationally and globally recognized certifications. Principle wise mapping has been provided as mentioned below:",
  codeCertificationsP1: "Quality Management System (9001:2015), RBA (Responsible Business Alliance) VAP",
  codeCertificationsP2: "Environmental management System (14001:2015), Energy Management System (50001:2018) ISO 9001, ISO 45001 certification",
  codeCertificationsP3: "Great Place to Work-Certification, Occupational Health and Safety Management System (45001:2018)",
  codeCertificationsP4: "Dixon does not have any code/certifications/labels aligning with Principle 4 of the NGRBCs. However, it engages with its key stakeholders on a periodic basis and addresses their queries and concerns adequately.",
  codeCertificationsP5: "Great Place to Work-Certification, Occupational Health and Safety Management System (45001:2018)",
  codeCertificationsP6: "Environmental management System (14001:2015) Energy Management System (50001:2018) ISO 9001, ISO 45001 certification",
  codeCertificationsP7: "Dixon does not have any code/certifications/labels aligning with Principle 7 of the NGRBCs",
  codeCertificationsP8: "Dixon does not have any code/certifications/labels aligning with Principle 8 of the NGRBCs",
  codeCertificationsP9: "ESD S20:20 RBA (Responsible Business Alliance) VAP",
  goals: "Environment: Energy Management and Emission Reduction • Become carbon neutral by 2030 • Persistently reduce greenhouse gas emissions (YoY) Waste Management • Recycle 100% of the e-waste by 2030 Water Management • Reduce water intensity by 5% by 2027 • Amplify rainwater harvesting capacity Biodiversity • Commitment to not set up plants or operational activities in World Heritage areas and IUCN category I-IV protected areas Social: • Zero occupational workplace fatality across all units (YoY) Governance: • Compliance with statutory standards on environmental parameters • Continue to apply innovative technologies to develop new products",
  performance: "From Financial Year 2022-23, Company has embarked on the formal ESG journey which includes conducting materiality assessment exercise with the stakeholders and setting targets on ESG parameters. Performance with respect to these targets would be tracked and assessed once these targets are set."
};

export const H2Data = {
  director: "The Company is deeply committed to achieving ESG-related objectives and continues to prioritize related agenda over the near and medium term. The Company’s focus on ESG parameters is best reflected through values that are imbibed in all spheres of activities of the Company. The Company has adopted a Code of Conduct which guides interactions with all key stakeholders including employees, vendors, communities, investors, environment & society at large. Moreover, Corporate Social Responsibility is an integral part of its culture. One of the key features of its CSR projects is focus on participatory and collaborative approach with the community. The Company is committed to conducting beneficial and fair business practices to the Labour, human capital and to the community. It provides employees and business associates with working conditions that are clean, safe, healthy and fair."
};

export const H3Data = {
  authority: "Mr. Sunil Vachani, Executive Chairman Telephone- 01204737200 Email: sunilvachani@dixoninfo.com Mr. Atul B. Lall, Vice Chairman & Managing Director Telephone: 0120-4737200 Email: atullall@dixoninfo.com"
};

export const H4Data = {
  authorityDetails: "The Board of Directors of the Company at it's meeting held on 25th July, 2023 constituted an ESG Committee for implementation and oversight of the sustainability and ESG related issues / strategy."
};

export const H5Data = {
  isPerformancep1: "Y",
  isPerformancep2: "Y",
  isPerformancep3: "Y",
  isPerformancep4: "Y",
  isPerformancep5: "Y",
  isPerformancep6: "Y",
  isPerformancep7: "Y",
  isPerformancep8: "Y",
  isPerformancep9: "Y",
  performanceDescription: "The Company is implementing and adhering to all the policies in accordance with the applicable laws and regulations. These policies are also reviewed on an as-needed basis by the Board of Directors and/or its committees with the aim of streamlining and establishing sustainable measures in place for the company.",
  isCompliancep1: "Y",
  isCompliancep2: "Y",
  isCompliancep3: "Y",
  isCompliancep4: "Y",
  isCompliancep5: "Y",
  isCompliancep6: "Y",
  isCompliancep7: "Y",
  isCompliancep8: "Y",
  isCompliancep9: "Y",
  complianceDescription: "The Company complies with all the regulatory and legal requirements and is periodically evaluated by the respective Board committees or Board of Directors. The Company ensures that it has implemented policies to address its significant material areas such as environmental protection, employee health and safety etc.",
  Performancefrequency: "",
  ComplianceFrequency: "",
};

export const H6Data = {
  isAssesmentP1: "No, The Company has various policies in place which are reviewed periodically by the Board and it's Committees.",
  isAssesmentP2: "",
  isAssesmentP3: "",
  isAssesmentP4: "",
  isAssesmentP5: "",
  isAssesmentP6: "",
  isAssesmentP7: "",
  isAssesmentP8: "",
  isAssesmentP9: "",
  nameP1: "",
  nameP2: "",
  nameP3: "",
  nameP4: "",
  nameP5: "",
  nameP6: "",
  nameP7: "",
  nameP8: "",
  nameP9: ""
};

export const H7Data = {
  isNotMaterialP1: "Not Applicable",
  isNotMaterialP2: "",
  isNotMaterialP3: "",
  isNotMaterialP4: "",
  isNotMaterialP5: "",
  isNotMaterialP6: "",
  isNotMaterialP7: "",
  isNotMaterialP8: "",
  isNotMaterialP9: "",
  isPositionP1: "",
  isPositionP2: "",
  isPositionP3: "",
  isPositionP4: "",
  isPositionP5: "",
  isPositionP6: "",
  isPositionP7: "",
  isPositionP8: "",
  isPositionP9: "",
  isResourceP1: "",
  isResourceP2: "",
  isResourceP3: "",
  isResourceP4: "",
  isResourceP5: "",
  isResourceP6: "",
  isResourceP7: "",
  isResourceP8: "",
  isResourceP9: "",
  isPlannedP1: "",
  isPlannedP2: "",
  isPlannedP3: "",
  isPlannedP4: "",
  isPlannedP5: "",
  isPlannedP6: "",
  isPlannedP7: "",
  isPlannedP8: "",
  isPlannedP9: "",
  isOtherP1: "",
  isOtherP2: "",
  isOtherP3: "",
  isOtherP4: "",
  isOtherP5: "",
  isOtherP6: "",
  isOtherP7: "",
  isOtherP8: "",
  isOtherP9: ""
};
