import { Divider } from "antd";
import {
  H1Data,
} from "./data.js";

const H1 = () => {
  return (
    <div className="text-padding-left">
      <p><strong>{H1Data.mechanism}</strong></p>  
      <Divider/>
    </div>
  );
};

export default H1;
