import { Divider } from "antd";
import {
  H6Data,
} from "./data.js";

const H5 = () => {
  return (
    <div className="text-padding-left">
      <p><strong>{H6Data.actions}</strong></p>  
      <Divider/>
    </div>
  );
};

export default H5;
