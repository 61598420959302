export const H1Data = {
    mechanism: "Company does not directly engage with the end customers of its products since it works on a B2B model.",
  };
  
  export const H2Data = {
    environmental: "Nil",
    safe: "Nil",
    recylce: "Nil",
  };
  
  export const H3Data = {
    privacyFile_23: "0",
    privacyPending_23: "0",
    privacyremarks_23: "-",
    advertisingFile_23: "0",
    advertisingPending_23: "0",
    advertisingRemarks_23: "-",
    cyberSecurityFile_23:"0",
    cyberSecurityPending_23:"0",
    cyberSecurityRemarks_23:"-",
    deliveryFile_23: "0",
    deliveryPending_23: "0",
    deliveryRemarks_23: "-",
    restrcitiveFile_23: "0",
    restrcitivePending_23: "0",
    restrcitiveRemarks_23: "-",
    unfairTradeFile_23: "0",
    unfairTradePending_23: "0",
    unfairRemarks_23: "-",
    otherFile_23: "0",
    otherPending_23: "0",
    otherRemarks_23: "-",

    privacyFile_22: "0",
    privacyPending_22: "0",
    privacyremarks_22: "-",
    advertisingFile_22: "0",
    advertisingPending_22: "0",
    advertisingRemarks_22: "-",
    cyberSecurityFile_22:"0",
    cyberSecurityPending_22:"0",
    cyberSecurityRemarks_22:"-",
    deliveryFile_22: "0",
    deliveryPending_22: "0",
    deliveryRemarks_22: "-",
    restrcitiveFile_22: "0",
    restrcitivePending_22: "0",
    restrcitiveRemarks_22: "-",
    unfairTradeFile_22: "0",
    unfairTradePending_22: "0",
    unfairRemarks_22: "-",
    otherFile_22: "0",
    otherPending_22: "0",
    otherRemarks_22: "-",
  };
  
  export const H4Data = {
    voluntaryNumber: "Nil",
    voluntaryReason: "-",
    forcedNumber: "Nil",
    forcedReason: "-",
  };
  
  export const H5Data = {
    isPolicy: "Yes, Dixon has the standalone data privacy policy.",
    link: "https://www.dixoninfo.com/codes-policies.php",
  };
  
  export const H6Data = {
    actions: "Not Applicable",
  };
  
  export const H7Data = {
    channel: "",
  };
  
  export const H8Data = {
    awarness: "",
  };
  
  export const H9Data = {
    mechanism: "",
  };
  
  export const H10Data = {
    display: "",
    survey: "",
  };
  
  export const H11Data = {
    impactData: "",
    customerData: "",
  };
  