import { H1Data } from "./data.js";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { appTheme } from "../../config.js";

const headerData19 = [
  { label: "", align: "center", rowSpan: 2 },
  { label: "Segment", rowSpan: 2 },
  { label: "Total number of training and awareness programs held", align: "center", rowSpan: 2 },
  { label: "Topics / principles covered under the training and its impact", align: "center", rowSpan: 2 },
  { label: "% of persons in respective category covered by the awareness programs", align: "center", rowSpan: 2 },
];


const renderDataRows = (data) =>
  data.map(({ id, label, data }) => (
    <TableRow key={id}>
      <TableCell component="th" scope="row" align="center">
        {id}
      </TableCell>
      <TableCell>{label}</TableCell>
      {data.map((value, index) => (
        <TableCell key={index} align="center">
          {value}
        </TableCell>
      ))}
    </TableRow>
  ));

const H1 = () => {
  return (
    <div>
      <TableContainer component={Paper}>
        <Table  size="small">
          <TableHead sx={{ "& th": { color: appTheme.fontColor.light } }}>
            <TableRow sx={{ backgroundColor: appTheme.colors.secondry }}>
              {headerData19.map(({ label, align, rowSpan, colSpan }, index) => (
                <TableCell
                  key={index}
                  align={align}
                  colSpan={colSpan}
                  rowSpan={rowSpan}
                >
                  <strong>{label}</strong>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ "& tr": { borderBottom: "1px solid #dee2e6" } }}>
            {renderDataRows([
              {
                id: 1,
                label: "Board of Directors",
                data: [
                  H1Data.boardTotal,
                  H1Data.boardPrinciple,
                  H1Data.boardPercentage
                ],
              },
              {
                id: 2,
                label: "Key Management Personnel",
                data: [
                  H1Data.kmpTotal,
                  H1Data.kmpPrinciple,
                  H1Data.kmpPercentage
                ],
              },
              {
                id: 3,
                label: "Employees other than BoD and KMPs",
                data: [
                  H1Data.employeeTotal,
                  H1Data.employeePrinciple,
                  H1Data.employeePercentage
                ],
              },
              {
                id: 4,
                label: "Workers",
                data: [
                  H1Data.contractualTotal,
                  H1Data.contractualPrinciple,
                  H1Data.contractualPercentage
                ],
              },
            ])}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default H1;
