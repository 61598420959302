import { H9Data } from "./data.js";

const H9 = () => {
  return (
    <div className="text-padding-left ">
      <p>{H9Data.wasteManagementPractices}</p>
    </div>
  );
};

export default H9;
